<template>
	<section id="dashboard-ecommerce">
		<b-row class="match-height">
			<b-col xl="4" md="6" sm="12">
				<dashboard-medal :data="topChofer" :loading="loading" />
			</b-col>
			<b-col xl="8" md="6" sm="12">
				<dashboard-statistics />
			</b-col>
		</b-row>

		<b-row class="match-height">
			<b-col lg="5" md="6" sm="12">
				<dashboard-ware-house-table />
			</b-col>
			<b-col lg="4" md="6" sm="12">
				<dashboard-operators-table />
			</b-col>
			<b-col lg="3" md="6" sm="12">
				<dashboard-binnacle />
			</b-col>
		</b-row>
	</section>
</template>

<script>

import {
	BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

import { ref, onMounted, computed, watch, reactive, defineEmits, nextTick } from "@vue/composition-api";
import DashboardStatistics from '@/@core/components/app-rountings/dashboard-administrators/Statistics.vue'
import DashboardMedal from '@/@core/components/app-rountings/dashboard-administrators/Medal.vue'
import DashboardBinnacle from '@/@core/components/app-rountings/dashboard-administrators/Binnacle.vue'
import DashboardWareHouseTable from '@/@core/components/app-rountings/dashboard-administrators/WareHouseTable.vue'
import DashboardOperatorsTable from '@/@core/components/app-rountings/dashboard-administrators/OperatorsTable.vue'

import DashboardsProvider from '@/providers/Dashboards'
const DashboardsResource = new DashboardsProvider()

export default {
	name: 'DashboardAdministrator',
	components: {
		BRow,
		BCol,
		BCard,
		BCardHeader,
		BCardTitle,
		BCardText,
		BCardBody,
		BMedia,
		BAvatar,
		BMediaAside,
		BMediaBody,

		//
		DashboardStatistics,
		DashboardMedal,
		DashboardBinnacle,
		DashboardWareHouseTable,
		DashboardOperatorsTable,
	},
	setup() {

		const loading = ref(false)
		const statisticsItems = ref([])
		const topChofer = ref({})

		const getStatistics = async () => {
			loading.value = true
			try {
				const { data } = await DashboardsResource.getStatisticRoutingByCurrentMonthByWareHouse()
			} catch (error) {
				console.log(error)
			} finally {
				loading.value = false
			}
		}

		onMounted(async () => {
			// await getTopChofer()
			// await getStatistics()
		})

		return {
			loading,
			statisticsItems,
			topChofer,

			//Methods
			//getTopChofer,
			//getStatistics
		}
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>