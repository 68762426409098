<template>
    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
        <b-card no-body>
            <b-card-header>
                <b-card-title>Cobranza de Clientes</b-card-title>
                <!-- <b-card-sub-title>
                    <b-button size="sm" @click="getCustomerCharges" variant="primary">
                        <feather-icon icon="RefreshCwIcon" />
                    </b-button>
                </b-card-sub-title> -->
                    <!-- <b-form-group label="Consultar a partir de" label-cols-sm label-for="date_start">
                        <flat-pickr v-model="filters.fechaIni" id="date_start" class="form-control flatpickr-small"
                        :config="{ dateFormat: 'Y-m-d' }" placeholder="DD/MM/YYYY" />                
                    </b-form-group>-->
            </b-card-header>

            <!-- <b-card-body v-show="charges"> -->
            <div class="chart-wrapper">
                <vue-apex-charts
                    type="pie"
                    ref="donutChart"
                    height="350"
                    :options="chartOptions"
                    :series="amounts"
                    @dataPointSelection="handleDataPointSelection"
                    :key="chartKey"
                />
            </div>
            <!-- </b-card-body> -->
        </b-card>
    </b-overlay>
</template>

<script>
import moment from 'moment'
import lodash from 'lodash'
import {
    BCard, BCardHeader, BCardTitle, BCardSubTitle, BDropdown, BDropdownItem, BCardBody,
    BFormGroup, BFormInput, BFormSelect, BFormCheckbox, BFormRadio, BFormRadioGroup, 
    BFormTextarea, BFormInvalidFeedback, BButton, BOverlay,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { computed, nextTick, onMounted, ref } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import { kFormatter } from '@core/utils/filter'
import DashboardsProvider from '@/providers/Dashboards'
const DashboardsResource = new DashboardsProvider()

export default {
    name: 'CustomerChargesDotnut',
    components: {
        BCard,
        BCardHeader,
        BCardTitle,
        BCardSubTitle,
        BDropdown,
        BDropdownItem,
        BCardBody,
        BFormGroup, 
        BFormInput, 
        BFormSelect, 
        BFormCheckbox, 
        BFormRadio, 
        BFormRadioGroup, 
        BFormTextarea, 
        BFormInvalidFeedback, 
        BButton,
        BOverlay,
        VueApexCharts,

        //
        flatPickr,
    },
    chartKey() {
        // Solo acceder a window en el cliente (navegador)
        if (typeof window !== 'undefined') {
            return `chart-${window.innerWidth}-${window.innerHeight}`;
        }
        return 'chart-default';
    },
    data() {
        return {
            loading: false,
            filters: {
                fechaIni: moment().add(-1, 'year').startOf('year').format('YYYY-MM-DD'),
                fechaFin: moment().format('YYYY-MM-DD'),
            },
            chartKey: 0,
            chartData: {},
            selectedPoint: null, // Almacena índices seleccionados           
            customersPie: {
                series: [],
                chartOptions: {
                    chart: {
                        toolbar: {
                            show: false,
                        },
                    },
                    labels: [],
                    dataLabels: {
                        enabled: true,
                        fontSize: '6px',
                        fontFamily: 'Montserrat',
                        formatter(val) {
                            if (val) {
                                return `${parseFloat(val).toFixed(2)}%`
                            }
                        },
                    },
                    tooltip: {
                        enabled: true, // Habilita el tooltip                        
                        y: {
                            formatter(val) {
                                if (val) {
                                    return kFormatter(val);
                                }
                            }
                        }
                    },
                    legend: { show: false },
                    stroke: {
                        width: 6,
                    },
                    colors: [
                        $themeColors.warning, // Por vencer
                        "#FFB3B3",            // Vencidas de 1 a 30 días (rojo claro)
                        $themeColors.danger,  // Vencidas de 31 a 60 días (rojo fuerte)
                        "#C70039",            // Vencidas de 61 a 90 días (rojo más oscuro)
                        $themeColors.dark,    // Vencidas de 91 días a un año (oscuro)
                        "#4E4E4E"             // Vencidas de un año en adelante (gris muy oscuro)
                    ],
                },
            },
            charges: [],  
            categories: [
                "Por vencer",
                "Vencidas de 1 a 30 días",
                "Vencidas de 31 a 60 días",
                "Vencidas de 61 a 90 días",
                "Vencidas de 91 días a un año",
                "Vencidas de un año en adelante"
            ],
            amounts: [], // Importes totales por categoría
            // chartOptions: {
            //     chart: {
            //         type: "pie",
            //         width: 380,
            //         animations: {
            //             enabled: false // Mejor rendimiento en redimensionamiento
            //         }
            //     },
            //     labels: [],
            //     tooltip: {
            //         enabled: true,
            //         y: {
            //             formatter: val => val ? kFormatter(val) : ''
            //         }
            //     },
            //     colors: [
            //         $themeColors.warning,
            //         "#FFB3B3",
            //         $themeColors.danger,
            //         "#C70039",
            //         $themeColors.dark,
            //         "#4E4E4E"
            //     ],
            //     dataLabels: {
            //         enabled: true, // Lo controlaremos dinámicamente
            //         style: {
            //             fontSize: '9px', // Valor por defecto
            //         fontFamily: 'Montserrat'
            //         },
            //         dropShadow: {
            //             enabled: false // Mejor legibilidad
            //         }
            //     },
            //     responsive: [
            //         {
            //             breakpoint: 992,
            //             options: {
            //                 chart: {
            //                     height: '380'
            //                 },
            //                 dataLabels: {
            //                     enabled: true,
            //                     style: { fontSize: '8px' }
            //                 }
            //             }
            //         },
            //         {
            //             breakpoint: 768,
            //             options: {
            //                 chart: {
            //                     height: 400
            //                 },
            //                 dataLabels: {
            //                     enabled: false
            //                 },
            //                 legend: {
            //                     position: 'bottom',
            //                     horizontalAlign: 'center',
            //                     fontSize: '10px'
            //                 }
            //             }
            //         },
            //         {
            //             breakpoint: 576,
            //             options: {
            //                 chart: {
            //                     height: 300
            //                 },
            //                 legend: {
            //                     fontSize: '8px',
            //                     itemMargin: {
            //                         horizontal: 1,
            //                         vertical: 1
            //                     }
            //                 }
            //             }
            //         }
            //     ]
            //     }
            chartOptions: {
                chart: {
                    type: "pie",
                    // width: '380', // Usar porcentaje en lugar de fijo
                    // height: '380', // Altura flexible
                    // parentHeightOffset: 0,  // Evita problemas de altura en resoluciones medianas
                },
                
                labels: [], // Se configurará dinámicamente
                tooltip: {
                    enabled: true, // Habilita el tooltip
                    y: {
                        formatter(val) {
                            if (val) {
                                return kFormatter(val);
                            }
                        }
                    }
                },
                colors: [
                    $themeColors.warning, // Por vencer
                    "#FFB3B3",            // Vencidas de 1 a 30 días (rojo claro)
                    $themeColors.danger,  // Vencidas de 31 a 60 días (rojo fuerte)
                    "#C70039",            // Vencidas de 61 a 90 días (rojo más oscuro)
                    $themeColors.dark,    // Vencidas de 91 días a un año (oscuro)
                    "#4E4E4E"             // Vencidas de un año en adelante (gris muy oscuro)
                ],
                dataLabels: {
                    enabled: window.innerWidth > 800, // Deshabilitar bajo 800px
                    style: {
                        fontSize: this.getDynamicFontSize(), // Tamaño dinámico
                    }
                },                
                responsive: [
                    {
                        breakpoint: 850,
                        options: {
                            chart: {
                                height: 380 // Altura reducida
                            },
                            dataLabels: {
                                enabled: true
                            },
                            legend: {
                                position: 'bottom',
                                horizontalAlign: 'center'
                            }
                        }
                    },
                    {
                        breakpoint: 700,
                        options: {
                            chart: {
                                height: 250
                            },
                            dataLabels: {
                                enabled: true
                            },
                            legend: {
                                fontSize: '9px',
                                itemMargin: {
                                    horizontal: 2,
                                    vertical: 1
                                }
                            }
                        }
                    },
                ]
            }
        }
    },
    created() {
        // this.chartOptions.labels = this.categories;
    },
    async mounted() {
        await this.getCustomerCharges()
    },
    methods: {
        kFormatter,
        handleResize() {
            const chart = this.$refs.donutChart;
            if (!chart) return;
            
            const width = window.innerWidth;
            const height = window.innerHeight;
            const aspectRatio = width / height;
            
            // Ajustes específicos para resoluciones problemáticas
            if (width > 850 && width < 900 && height > 650 && height < 700) {
            chart.updateOptions({
                chart: {
                height: Math.min(width * 0.8, height * 0.6)
                },
                dataLabels: {
                enabled: width > 700,
                style: { fontSize: '7px' }
                }
            }, false, true);
            }
        },
        getDynamicFontSize() {
            const width = window.innerWidth;
            if (width > 1000) return '12px';
            if (width > 800) return '10px';
            if (width > 600) return '8px';
            return '6px';
        },
        handleChartResize() {
            if (this.$refs.donutChart) {
            this.$refs.donutChart.updateOptions({
                dataLabels: {
                enabled: window.innerWidth > 800,
                style: {
                    fontSize: this.getDynamicFontSize()
                }
                }
            }, false, true);
            }
        },
        mapColorToClass(hexColor) {
            const colorMap = {
                [$themeColors.primary]: 'primary',
                [$themeColors.warning]: 'warning',
                [$themeColors.danger]: 'danger',
                [$themeColors.success]: 'success',
                [$themeColors.info]: 'info',
                [$themeColors.secondary]: 'secondary',
            };
            return colorMap[hexColor] || 'muted'; // Devuelve un color predeterminado si no se encuentra.
        },
        oldhandleDataPointSelection(event, chartContext, config) {
            const { dataPointIndex } = config;

            //if (dataPointIndex === undefined || dataPointIndex === null) return;

            // Forzamos la actualización
            this.selectedPoint = null;
            this.$nextTick(() => {
                this.selectedPoint = dataPointIndex;
            });

            // Emitimos el evento
            const category = this.chartOptions.labels[dataPointIndex];
            const value = this.amounts[dataPointIndex];
            this.$emit('dataPointSelected', category, value, this.selectedPoint);
        },

        handleDataPointSelection(event, chartContext, config) {

            // console.log([event, chartContext, config]);

            const { dataPointIndex } = config; // Índice del punto seleccionado

            if (this.selectedPoint === dataPointIndex) {
                // Si el mismo punto está seleccionado, lo deseleccionamos
                this.selectedPoint = null;
            } else {
                // Seleccionamos un nuevo punto
                this.selectedPoint = dataPointIndex;
            }

            // El índice seleccionado está en config.dataPointIndex
            const categoryIndex = dataPointIndex;
            const category = this.chartOptions.labels[categoryIndex];
            const value = this.amounts[categoryIndex];

            // Aquí puedes abrir un modal, navegar a otra página, o mostrar más detalles
            this.$emit('dataPointSelected', category, value, this.selectedPoint);

        },
        async getCustomerCharges() {
            this.charges = []
            this.loading = true

            try {
                const { data } = await DashboardsResource.getCustomerCharges(this.filters)
                if (data.isSuccesful) {
                    if (data.data.length == 0) {
                        this.danger('No se encontraron facturas')
                    } else {
                        this.charges = data.data

                        // Inicializar las categorías.
                        const categories = {
                            "Por vencer": 0,
                            "Vencidas de 1 a 30 días": 0,
                            "Vencidas de 31 a 60 días": 0,
                            "Vencidas de 61 a 90 días": 0,
                            "Vencidas de 91 días a un año": 0,
                            "Vencidas de un año en adelante": 0,
                        };

                        this.charges.forEach((charge) => {
                            const atraso = charge.atraso;
                            const saldo = charge.saldo_Cargo;
                            if (atraso <= 0) {
                                categories["Por vencer"] += saldo;
                            } else if (atraso >= 1 && atraso <= 30) {
                                categories["Vencidas de 1 a 30 días"] += saldo;
                            } else if (atraso >= 31 && atraso <= 60) {
                                categories["Vencidas de 31 a 60 días"] += saldo;
                            } else if (atraso >= 61 && atraso <= 90) {
                                categories["Vencidas de 61 a 90 días"] += saldo;
                            } else if (atraso >= 91 && atraso <= 365) {
                                categories["Vencidas de 91 días a un año"] += saldo;
                            } else {
                                categories["Vencidas de un año en adelante"] += saldo;
                            }
                        });

                        // Actualizar las series y labels.
                        this.chartOptions.labels = this.categories;
                        this.amounts = Object.values(categories);
                        this.chartKey += 1;

                        // this.$nextTick(() => {
                        //     // this.$refs.donutChart.updateSeries(this.chartOptions.series, true);
                        //     this.$refs.donutChart.updateOptions(this.chartOptions, true, true, true);
                        // });
                    }
                } else {
                    this.danger(data.message)
                }
            } catch (error) {
                this.handleResponseErrors(error)
            } finally {
                this.loading = false
            }
        }
    },
    watch: {
        amounts: {
            handler() {
                this.$nextTick(() => {
                    if (this.$refs.donutChart) {
                        // this.$refs.donutChart.updateSeries(this.amounts, true);
                    }
                });
            },
            deep: true
        }
    }
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>
/* .chart-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 100%; /* Mantiene relación 1:1 *
  min-height: 300px;
}

.chart-wrapper .apexcharts-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
} */
</style>