<template>
	<section>
		<b-row class="match-height">
			<b-col cols="12" xl="6" md="6" lg="6">
				<sales-all-sellers :filterBy="filterBy" :refresh="false" />
			</b-col>
			<b-col cols="12" xl="6" md="6" lg="6">
				<sales-disccounts />
			</b-col>
            <b-col cols="12" xl="6" md="6" lg="6">
				<customer-charges-dotnut v-show="delay == 0 || delay == null" @dataPointSelected="optionSelected"/>
				<customer-charges v-show="delay > 0" :sellers="[]" :hiddenFilters="true" :title="title" :delay="delay" @setDelay="delay = 0" :showReturn="true"/>
			</b-col>
			<b-col cols="12" xl="6" md="6" lg="6">
				<accounting-policies />
			</b-col>
            <b-col cols="12" xl="6" md="6" lg="6">
				<income-statement />
			</b-col>
			<b-col cols="12" xl="6" md="6" lg="6">
				<income-flow />
			</b-col>
		</b-row>		
	</section>
</template>

<script>

import {
	BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
	BFormGroup, BFormInput, BFormSelect, BFormCheckbox, BFormRadio, BFormRadioGroup, BFormTextarea, BFormInvalidFeedback,
	BButton,
} from 'bootstrap-vue'
import store from '@/store';
import Swal from "sweetalert2";
import "animate.css";
import flatPickr from "vue-flatpickr-component";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import * as helper from '@/libs/helpers';
import vSelect from "vue-select";
import { v4 as uuidv4 } from 'uuid';
import router from "@/router";
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'
import moment from 'moment';

import { ref, onMounted, computed, watch, reactive, defineEmits, nextTick } from "@vue/composition-api";
import SalesAllSellers from '@/@core/components/sellers/SalesAllSellers.vue';
import SalesDisccounts from '@/@core/components/SalesDisccounts.vue';
import CustomerCharges from '@/@core/components/invoices/CustomerCharges.vue';
import SaleGoals from '@/@core/components/SaleGoals.vue';
import CustomerChargesDotnut from '@/@core/components/CustomerChargesDotnut.vue';
import AccountingPolicies from '@/@core/components/AccountingPolicies.vue';
import IncomeStatement from '@/@core/components/IncomeStatement.vue';
import IncomeFlow from '@/@core/components/IncomeFlow.vue';

import DashboardsProvider from '@/providers/Dashboards'
const DashboardsResource = new DashboardsProvider()

export default {
	name: 'DashboardAdministrator',
	components: {
		BRow,
		BCol,
		BCard,
		BCardHeader,
		BCardTitle,
		BCardText,
		BCardBody,
		BMedia,
		BAvatar,
		BMediaAside,
		BMediaBody,
		BFormGroup, 
		BFormInput, 
		BFormSelect, 
		BFormCheckbox, 
		BFormRadio, 
		BFormRadioGroup, 
		BFormTextarea, 
		BFormInvalidFeedback,
		BButton,

		//
		SalesAllSellers,
		SalesDisccounts,
		SaleGoals,
		CustomerChargesDotnut,
		CustomerCharges,
		AccountingPolicies,
		IncomeStatement,
		IncomeFlow,

		//
		flatPickr,
        vSelect,
	},
	mixins: [heightTransition],
	setup() {

		const loading = ref(false)
		const filterSearch = ref({
			fechaIni: moment().format('YYYY-MM-DD'),
			fechaFin: moment().format('YYYY-MM-DD'),
			tipoBitacora: null,
		})
		const delay = ref(0)
		const title = ref('Facturas Clientes')

		const filterDates = ref([
			{ value: 0, label: 'Todas'},
			{ value: 1, label: 'Por vencer'},
			{ value: 2, label: 'Vencidas de 1 a 30 días'},
			{ value: 3, label: 'Vencidas de 31 a 60 días'},
			{ value: 4, label: 'Vencidas de 61 a 90 días'},
			{ value: 5, label: 'Vencidas de 91 días a un año'},
			{ value: 6, label: 'Vencidas de un año en adelante'},
		])

		const filterBy = computed(() => {
			const filter = store.getters['auth/getFilterOnDashboardSeller']
			const seller_id = store.getters['auth/getSellerId']
			return {
				...filter,
				vendedor_Id: seller_id
			}
		})

		const optionSelected = (data, value, points) => {
			// console.log("🔹 Datos recibidos:", { data, value, points });

			// Aseguramos que data existe y se compara correctamente
			const filter = filterDates.value.find(item => item.label.trim().toLowerCase() === data.trim().toLowerCase());

			if (filter) {
				title.value = `Facturas Clientes ${filter.label}`;
				delay.value = points === 0 ? 1 : filter.value;
			} else {
				console.warn("⚠ No se encontró el filtro para:", data);
			}

			if (points == null) {
				delay.value = 0;
			}

			// console.log("🔹 Datos actualizados:", { title: title.value, delay: delay.value });
		};


		return {
			loading,
			filterSearch,
			filterBy,
			title,
			delay,

			//
			optionSelected,
		}	

	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
@import "~@core/scss/vue/libs/vue-sweetalert.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
@import '~@core/scss/vue/libs/vue-flatpicker.scss';

</style>

<style>
/* .match-height>[class*=col] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
}

.match-height {
  display: flex;
  flex-wrap: wrap;
}
.match-height > .col {
  display: flex;
}
.match-height > .col > * {
  flex: 1;
} */
</style>