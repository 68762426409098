<template>
    <div style="height: inherit">
        <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
            <section id="ecommerce-header">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="ecommerce-header-items">
                            <div class="result-toggler">
                                <feather-icon
                                        icon="MenuIcon"
                                        class="d-block d-lg-none"
                                        size="21"
                                        @click="mqShallShowLeftSidebar = true"
                                    />
                                <div class="search-results">
                                    {{ totalProducts }} Resultados encontrados
                                </div>
                            </div>
                            <div class="view-options d-flex">

                                <!-- Sort Button -->
                                <!-- <b-dropdown v-ripple.400="'rgba(113, 102, 240, 0.15)'" :text="sortBy.text" right
                                    variant="outline-primary">
                                    <b-dropdown-item v-for="sortOption in sortByOptions" :key="sortOption.value"
                                        @click="sortBy = sortOption">
                                        {{ sortOption.text }}
                                    </b-dropdown-item>
                                </b-dropdown> -->

                                <!-- Item View Radio Button Group  -->
                                <!-- <b-form-radio-group v-model="itemView" class="ml-1 list item-view-radio-group" buttons
                                    size="sm" button-variant="outline-primary">
                                    <b-form-radio v-for="option in itemViewOptions" :key="option.value"
                                        :value="option.value">
                                        <feather-icon :icon="option.icon" size="18" />
                                    </b-form-radio>
                                </b-form-radio-group> -->

                                <b-pagination v-if="totalProducts > pageSize" v-model="pageNumber" :total-rows="totalProducts" :per-page="pageSize"
                                    first-number align="center" last-number prev-class="prev-item" next-class="next-item">
                                    <template #prev-text>
                                        <feather-icon icon="ChevronLeftIcon" size="18" />
                                    </template>
                                    <template #next-text>
                                        <feather-icon icon="ChevronRightIcon" size="18" />
                                    </template>
                                </b-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Overlay -->
            <div class="body-content-overlay" />

            <!-- Searchbar -->
            <div class="ecommerce-searchbar mt-1">
                <b-row>
                    <b-col cols="12">
                        <b-input-group class="input-group-merge">
                            <b-form-input ref="inputSearch" v-model="search" placeholder="Escanea el código de barras"
                                class="search-product" autofocus @keydown.enter="searchArticleByQr(1)" />
                            <b-input-group-append is-text>
                                <feather-icon @click="searchArticleByQr(1)" icon="SearchIcon" class="text-muted" />
                            </b-input-group-append>
                        </b-input-group>
                    </b-col>
                </b-row>
            </div>

            <!-- Content -->
            <section :class="itemView">
                <div v-if="!isMobile">
                    <b-card v-for="(product, index) in products" :key="product.name + index" class="ecommerce-card" no-body>
                        <div class="item-img text-center">
                            <b-link>
                                <b-img v-if="product.image_Exists" :alt="`${product.id}-${index}`" fluid :class="`card-img-top`"
                                    :src="convertirABase64(product.imagen)" />
                                <b-img v-else :alt="`${product.id}-${index}`" width="100" height="100" :src="logo_1" />
                            </b-link>
                        </div>

                        <!-- Product Details -->
                        <b-card-body>
                            <h6 class="item-name">
                                <b-link class="text-body">
                                    {{ product.name }}
                                </b-link>
                            </h6>
                            <b-card-text class="item-description">
                                <strong>Clave Principal: </strong> {{ product.clabe }}<br>
                                <strong>Código Barras: </strong> {{ product.code_bar }}<br>
                                <strong>Unidad Venta: </strong> {{ product.unit }}
                            </b-card-text>
                            <b-card-text class="item-description">
                                {{ product.isAvailable ? '' : 'No' }} Disponible - <span
                                    :class="`text-${product.isAvailable ? 'success' : 'danger'}`">En stock</span>
                                <b-list-group v-if="product.isAvailable">
                                    <b-list-group-item v-for="(existencia, idxE) in product.existencias" :key="idxE"
                                        class="d-flex justify-content-between align-items-center"
                                        style="padding: 0.25rem!important;">
                                        <small>{{ existencia.almacen }}</small>
                                        <b-badge variant="primary" pill class="badge-round">
                                            {{ existencia.existencia }}
                                        </b-badge>
                                    </b-list-group-item>
                                </b-list-group>
                            </b-card-text>
                        </b-card-body>

                        <!-- Product Actions -->
                        <div class="item-options text-center">
                            <div class="item-wrapper">
                                <div class="item-cost">
                                    <h6 class="item-price_">Precio {{ formatCurrency(product.price) }}</h6>
                                </div>
                            </div>

                            <div class="item-wrapper">
                                <div class="item-cost">
                                    <h6 class="item-price_">IVA {{ formatCurrency(product.tax) }}</h6>
                                </div>
                            </div>

                            <div class="item-wrapper">
                                <div class="item-cost">
                                    <h3 class="item-price">Precio Neto {{ formatCurrency(product.price_net) }}</h3>
                                </div>
                            </div>
                        </div>
                    </b-card>
                </div>
                <div v-else>
                    <b-card v-for="(product, index) in products" :key="product.name + index" no-body class="ecommerce-card">
                        <b-card-body>
                            <b-row class="my-2">

                                <!-- Left: Product Image Container -->
                                <b-col cols="12" md="5" class="d-flex align-items-center justify-content-center mb-2 mb-md-0">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <b-img v-if="product.image_Exists" :src="convertirABase64(product.imagen)" :alt="`Image of ${product.name}`" class="product-img" fluid />
                                        <b-img v-else :src="logo_1" :alt="`Image of ${product.name}`" class="_product-img" width="100" height="100" />
                                    </div>
                                </b-col>

                                <!-- Right: Product Details -->
                                <b-col cols="12" md="7">

                                    <!-- Product Name -->
                                    <h4>{{ product.name }}</h4>

                                    <!-- Product Attributes -->
                                    <b-card-text class="item-company mb-0">
                                        <strong>Clave Principal: </strong> {{ product.clabe }}<br>
                                        <strong>Código Barras: </strong> {{ product.code_bar }}<br>
                                        <strong>Unidad Venta: </strong> {{ product.unit }}
                                    </b-card-text>

                                    <!-- Price And Ratings -->
                                    <div class="item-company item-options text-center mt-1">
                                        <div class="item-wrapper">
                                            <div class="item-cost">
                                                <h6 class="item-price_">Precio {{ formatCurrency(product.price) }}</h6>
                                            </div>
                                        </div>

                                        <div class="item-wrapper">
                                            <div class="item-cost">
                                                <h6 class="item-price_">IVA {{ formatCurrency(product.tax) }}</h6>
                                            </div>
                                        </div>

                                        <div class="item-wrapper">
                                            <div class="item-cost">                                        
                                                <h3 class="item-price text-primary">Precio Neto {{ formatCurrency(product.price_net) }}</h3>
                                            </div>
                                        </div>
                                    </div>                            

                                    <!-- Avability -->
                                    <b-card-text class="mt-1">
                                        {{ product.isAvailable ? '' : 'No' }} Disponible - <span :class="`text-${product.isAvailable ? 'success' : 'danger'}`">En stock</span>
                                        <b-list-group v-if="product.isAvailable">
                                            <b-list-group-item v-for="(existencia, idxE) in product.existencias" :key="idxE"
                                                class="d-flex justify-content-between align-items-center"
                                                style="padding: 0.25rem!important;">
                                                <small>{{ existencia.almacen }}</small>
                                                <b-badge variant="primary" pill class="badge-round">
                                                    {{ existencia.existencia }}
                                                </b-badge>
                                            </b-list-group-item>
                                        </b-list-group>
                                    </b-card-text>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </div>
            </section>            

            <!-- Pagination -->
            <section v-if="totalProducts > pageSize">
                <b-row>
                    <b-col cols="12">
                        <b-pagination v-model="pageNumber" :total-rows="totalProducts" :per-page="pageSize" first-number
                            align="center" last-number prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </section>            
        </b-overlay>

        <portal to="content-renderer-sidebar-detached-left">
            <scan-code-left-filter-sidebar
                :filters="filters"
                :filter-options="filterOptions"
                :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
            />
        </portal>
    </div>
</template>

<script>

import {
    BDropdown, BDropdownItem, BFormRadioGroup, BFormRadio, BRow, BCol, BInputGroup, BInputGroupAppend, BFormInput, BCard, BCardBody, BLink, BImg, BCardText, BButton, BPagination,
    BOverlay, BBadge, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { watch, ref, onMounted, } from '@vue/composition-api'
import * as helper from '@/libs/helpers';
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ScanCodeLeftFilterSidebar from './ScanCodeLeftFilterSidebar.vue';
import ArticlesProvider from '@/providers/Articles'
const ArticlesResource = new ArticlesProvider()

import PricesArticlesProvider from '@/providers/PricesArticles';
import store from '@/store'
const PricesArticlesResource = new PricesArticlesProvider();

export default {
    name: 'ScanCode',
    directives: {
        Ripple,
    },
    components: {
        // BSV
        BDropdown,
        BDropdownItem,
        BFormRadioGroup,
        BFormRadio,
        BRow,
        BCol,
        BInputGroup,
        BInputGroupAppend,
        BFormInput,
        BCard,
        BCardBody,
        BLink,
        BImg,
        BCardText,
        BButton,
        BPagination,
        BOverlay,
        BBadge,
        BListGroup,
        BListGroupItem,
        BPagination,
        VuePerfectScrollbar,

        //
        ScanCodeLeftFilterSidebar,

    },
    data() {
        return {
            logo: require('@/assets/images/logo/ferreysa.png'),
            logo_1: require('@/assets/images/logo/ferreysa_1.jpg'),
            logo_2: require('@/assets/images/logo/ferreysa_2.jpg'),
            logo_3: require('@/assets/images/logo/ferreysa_3.jpg'),
            isMobile: false,
        }
    },
    computed: {
        totalRows() {
            return this.products.length
        },
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize()
    },
    methods: {
        handleResize() {
            this.isMobile = window.innerWidth < 576;
        },
    },
    setup() {
        const loading = ref(false)
        const search = ref('')
        const products = ref([])
        const product = ref(null)
        const itemView = ref('list-view')
        const itemViewOptions = ref([
            { icon: 'GridIcon', value: 'grid-view' },
            { icon: 'ListIcon', value: 'list-view' },
        ])
        const pageNumber = ref(1)
        const pageSize = ref(10)
        const totalProducts = ref(0)
        const mqShallShowLeftSidebar = ref(false)

        const filterOptions = ref({
            pricesLists: [],
        })

        const filters = ref({
            pricesList: 42,
        })

        const perfectScrollbarSettings = {
            maxScrollbarLength: 60,
            wheelPropagation: true,
        }

        // Define la referencia
        const inputSearch = ref(null);

        // Función para enfocar el input
        const focusInput = () => {
            if (inputSearch.value) {
                inputSearch.value.focus(); // Usa .value para acceder al elemento
            }
        };

        const getAllPricesList = async () => {
            filterOptions.value.pricesLists = []
            try {
                const companyId = store.getters['auth/getConexionId']
                const { data } = await PricesArticlesResource.getAllPricesList(companyId)
                filterOptions.value.pricesLists = data.data.map(priceList => {
                    return {
                        text: priceList.nombre,
                        value: priceList.precio_Empresa_Id,
                    }
                })
            } catch (e) {
                console.log(e)
                helper.handleResponseErrors(e)
            }
        }

        const searchArticleByQr = async (page = 1) => {

            if (!search.value) {
                helper.danger('Ingrese un código de barras, clave principal o nombre de producto')
                return
            }

            products.value = []
            totalProducts.value = 0

            const query = {
                search: search.value,
                pageNumber: page,
                pageSize: pageSize.value,
                precioLista: filters.value.pricesList,
            }

            try {
                loading.value = true
                const { data } = await ArticlesResource.searchArticlesByQr(query)
                loading.value = false
                if (data.isSuccesful && data.data.length > 0) {
                    products.value = _.orderBy(data.data.map(article => {
                        return {
                            id: article.articulo_Id,
                            name: article.articulo,
                            slug: article.articulo_Id,
                            price: article.precio,
                            tax: article.iva,
                            price_net: article.precio_Neto,
                            description: article.articulo,
                            brand: '',
                            rating: 5,
                            imagen: article.imagen,
                            unit: article.unidad_Venta,
                            isInWishlist: false,
                            isInCart: false,
                            isAvailable: article.existencias.reduce((acc, existencia) => acc + existencia.existencia, 0) > 0,
                            clabe: article.clave_Articulo,
                            code_bar: article.codigo_Barras,
                            existencias: article.existencias,
                            precios: article.preciosEmpresa,
                            image_Exists: article.imagen_Exists,
                        }
                    }), ['clabe'], ['desc'])



                    totalProducts.value = data.totalRecords
                } else {
                    helper.danger('No se encontraron resultados')
                }
            } catch (e) {
                console.log(e)
                helper.handleResponseErrors(e)
            } finally {
                loading.value = false
            }

            // search.value = ''
            focusInput()
        }

        onMounted( async () => {
            focusInput();
            await getAllPricesList()
        });

        // Verifica cuando el número de página cambie y actualiza los artículos
        watch(pageNumber, (newPage) => {
            console.log('newPage', newPage)
            searchArticleByQr(newPage)
        })

        watch([filters], (filter) => {            
            const priceListId = filter[0].pricesList
            if (priceListId) {
                products.value = products.value.map(product => {
                    const price = product.precios.find(precio => precio.precio_Empresa_Id === priceListId)
                    if (price) {
                        product.price = price.precio
                        product.tax = price.precio_Con_Impto - price.precio
                        product.price_net = price.precio_Con_Impto
                    } else {
                        product.price = 0
                        product.tax = 0
                        product.price_net = 0
                    }
                    return product
                })
            }
        }, 
        {
            deep: true,
        })

        watch(mqShallShowLeftSidebar, (newVal) => {
            console.log('mqShallShowLeftSidebar', newVal)
        })

        return {
            loading,
            search,
            products,
            product,
            inputSearch,
            itemView,
            itemViewOptions,
            pageNumber,
            pageSize,
            totalProducts,
            perfectScrollbarSettings,
            mqShallShowLeftSidebar,
            filterOptions,
            filters,

            //
            searchArticleByQr,

        }
    },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";

//ecommerce header
.ecommerce-header-items {
    display: flex;
    justify-content: space-between;

    .result-toggler {
        display: flex;
        align-items: center;

        // Sidebar toggler
        .shop-sidebar-toggler {
            padding-left: 0;

            &:active,
            &:focus {
                outline: 0;
            }

            .navbar-toggler-icon {
                height: auto;

                i,
                svg {
                    color: $body-color;
                    height: 1.5rem;
                    width: 1.5rem;
                    font-size: 1.5rem;
                }
            }
        }

        // Search result title
        .search-results {
            font-weight: $font-weight-bold;
            color: $headings-color;
        }
    }
}

// Product search bar
.ecommerce-searchbar {
    .input-group {
        box-shadow: 0 2px 8px 0 rgba($black, 0.14);
        border-radius: 0.3rem;
    }
}

// Search bar
.search-product,
.input-group-text {
    height: $search-bar-height;
    border: none;
    font-size: 0.95rem;
    padding-left: 1.25rem;

    &::placeholder {
        font-size: 0.95rem;
    }
}

// main Products
.ecommerce-card {
    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 4px 25px 0 rgba($black, 0.25);
    }

    // ratings
    .item-rating {
        ul {
            margin-bottom: 0;
        }

        svg,
        i {
            height: 1.143rem;
            width: 1.143rem;
            font-size: 1.143rem;
        }
    }

    // Item name
    .item-name {
        margin-bottom: 0;

        a {
            font-weight: $font-weight-bolder;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .item-description {
        font-size: 0.875rem;
    }

    // buttons style
    .btn-wishlist,
    .btn-cart {
        span {
            vertical-align: text-top;
        }

        i,
        svg {
            margin-right: 0.25rem;
            vertical-align: text-top;

            &.text-danger {
                fill: $danger;
            }
        }
    }
}

// margin for both views
.grid-view,
.list-view {
    &:not(.wishlist-items) {
        margin-top: 2rem;
    }
}

// Grid View Specific style
.grid-view {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2rem;

    &.wishlist-items {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .ecommerce-card {
        overflow: hidden;

        // Item Image
        .item-img {
            padding-top: 0.5rem;
            min-height: 15.85rem;
            display: flex;
            align-items: center;
        }

        // Item content wrapper
        .item-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
        }

        // Hiding list view items
        .shipping,
        .item-company,
        .item-options .item-price {
            display: none;
        }

        .item-options {
            display: flex;
            flex-wrap: wrap;

            .btn-cart,
            .btn-wishlist {
                flex-grow: 1;
                border-radius: 0;
            }
        }

        .item-name {
            margin-top: 0.75rem;
        }

        // Item Description
        .item-description {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-top: 0.2rem;
        }

        .item-price {
            font-weight: $font-weight-bolder;
        }

        .card-body {
            padding: 1rem;
        }
    }
}

// List View Specific style
.list-view {
    display: grid;
    grid-template-columns: 1fr;

    .ecommerce-card {
        overflow: hidden;
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;

        // list view image
        .item-img {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }

        .card-body {
            padding: 1.5rem 1rem;
            border-right: 1px solid $border-color;
            display: flex;
            flex-direction: column;

            // item name and ratings order
            .item-wrapper {
                order: 2;
            }

            .item-name {
                order: 1;
            }

            // List view description
            .item-description {
                order: 3;
                display: -webkit-box;
                -webkit-line-clamp: 5;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            // .item-rating,
            .item-price {
                display: none;
            }

            // ratings
            .item-rating {
                margin-bottom: 0.3rem;
            }
        }

        // Common for list-view, checkout and wishlist
        .item-company {
            display: inline-flex;
            font-weight: $font-weight-normal;
            margin: 0.3rem 0 0.5rem;
            font-size: 0.875rem;

            .company-name {
                font-weight: $font-weight-bolder;
                margin-left: 0.25rem;
            }
        }

        // price and btn area of list view
        .item-options {
            padding: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .item-wrapper {
                position: relative;

                .item-cost {
                    .item-price {
                        color: $primary;
                        margin-bottom: 0;
                    }
                }
            }

            // Shipping free tag
            .shipping {
                margin-top: 0.75rem;
            }

            // List view btn spacing
            .btn-wishlist,
            .btn-cart {
                margin-top: 1rem;
            }
        }
    }
}

// Media queries to change grid columns in different Screens and to hide search results below 768px
@include media-breakpoint-down(lg) {
  .ecommerce-application {
    // Header item area
    .ecommerce-header-items {
      .btn-group {
        align-items: center;
        .btn-icon {
          padding: 0.6rem 0.736rem;
        }
      }
    }
    .grid-view.wishlist-items {
      grid-template-columns: 1fr 1fr 1fr;
    }
    // overlay style for e-commerce page
    .body-content-overlay {
      position: fixed;
      opacity: 0;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      &.show {
        opacity: 1;
      }
    }

    // Horizontal Menu scss
    &.horizontal-layout {
      .body-content-overlay {
        z-index: 998 !important;
      }
      .sidebar-shop {
        z-index: 999 !important;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .ecommerce-application {
    .sidebar-left {
      .sidebar {
        // removed radius and margin as showing sidebar in full height
        .card {
          border-radius: 0;
          margin: 0;
        }
        // Sidebar style
        .sidebar-shop {
          transform: translateX(-112%);
          transition: all 0.25s ease;
          position: fixed;
          top: 0;
          left: 0;
          height: 100%;
          overflow-y: scroll;
          margin: 0;

          &.show {
            transition: all 0.25s ease;
            transform: translateX(0);
          }
        }
      }
    }
    // Grid view for responsive
    .grid-view {
      grid-template-columns: 1fr 1fr;
    }

    // to hide search results
    .ecommerce-header-items {
      .result-toggler {
        .search-results {
          display: none;
        }
      }
    }
  }
}
@include media-breakpoint-down(sm) {
  .ecommerce-application {
    .grid-view.wishlist-items {
      grid-template-columns: 1fr 1fr;
    }
    // List View
    .list-view {
      .ecommerce-card {
        grid-template-columns: 1fr;

        .item-img {
          padding-top: 2rem;
          padding-bottom: 2rem;
        }

        // to remove card-body border
        .card-body {
          border: none;
        }
      }
    }
  }
}
@include media-breakpoint-down(xs) {
  .ecommerce-application {
    // Grid View
    .grid-view,
    .grid-view.wishlist-items {
      grid-template-columns: 1fr;
    }
  }
}

</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
    .btn {
        display: flex;
        align-items: center;
    }
}
</style>