<template>
  <b-overlay
    :show="loading"
    blur="2px"
    variant="transparent"
    rounded="lg"
    opacity="0.85"
  >
    <b-card no-body>
		<b-card-header>
			<b-card-title>
				Estado de Resultados
			</b-card-title>

            <b-card-sub-title>
                <b-form-checkbox v-model="changeByCogs" class="custom-control-primary font-weight-bold">
                    COGS
                </b-form-checkbox>		
            </b-card-sub-title>
      	</b-card-header>

        <div class="ml-1 mr-1">
            <b-row>
                <b-col cols="12" md="6" lg="3">
                    <b-form-group label="Fecha Inicio" label-for="date_start">
                    <flat-pickr
                        v-model="filter.fechaIni"
                        id="date_start"
                        class="form-control flatpickr-small"
                        :config="{ dateFormat: 'Y-m-d' }"
                        placeholder="DD/MM/YYYY"
                    />
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="6" lg="3">
                    <b-form-group label="Fecha Fin" label-for="date_start">
                    <flat-pickr
                        v-model="filter.fechaFin"
                        id="date_end"
                        class="form-control flatpickr-small"
                        :config="{ dateFormat: 'Y-m-d' }"
                        placeholder="DD/MM/YYYY"
                    />
                    </b-form-group>
                </b-col>	
                
                <b-col cols="12" md="6" lg="4">
                    <b-form-group label="Almacén" label-for="warehouse_id">
                        <v-select
                            id="warehouse_id"
                            v-model="filter.almacenId"
                            :options="warehouses"
                            :reduce="option => option.almacen_Id"
                            label="nombre"
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            placeholder="Seleccione un almacén"
                            :clearable="false"
                            :searchable="false"
                            class="select-size-sm"/>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="6" lg="2" class="mt-2">
                    <b-button block :disabled="loading" @click="getSales" size="sm" variant="primary" class="mr-1">
                        <feather-icon icon="SearchIcon" /> Buscar
                    </b-button>
                </b-col>								
            </b-row>
        </div>

        <div class="table-responsive">
            <table role="table" class="table b-table table-sm b-table-no-border-collapse b-table-selectable b-table-select-single 
			w-100 text-small small table-no-inner-borders table-no-outer-border" :class="`_b-table-sticky-header`">
                <thead>
                    <tr>
                        <th scope="col" aria-colindex="1">
                            <div>Concepto</div>
                        </th>
                        <th scope="col" aria-colindex="2">
                            <div>Importe</div>
                        </th>
                        <th scope="col" aria-colindex="2">
                            <div>Impuestos</div>
                        </th>
                    </tr>
                </thead>
                <tbody>
					<!-- Ventas Credito/Debito -->
                    <template v-for="(item, index) in sales">
                        <tr role="row" @click="toggleDetails(index, 'sales')" :key="index">
                            <td :aria-colindex="index" role="cell"><strong>{{ item.tipoDocto }}</strong></td>
                            <td :aria-colindex="index" role="cell"><strong>{{ formatCurrency(item.totalPorTipoDocto) }}</strong></td>
                            <td :aria-colindex="index" role="cell"><strong>{{ formatCurrency(item.totalImpuestos) }}</strong></td>
                        </tr>
                        <tr v-if="item.showDetails" :key="index + '-details'">
                            <td colspan="3">
                                <div class="details-container">
                                    <h4>Detalles de {{ item.tipoDocto }}</h4>
                                    <b-list-group>
                                        <b-list-group-item v-for="(detalle, detalleIndex) in item.detallesPorTipoDocto" :key="detalleIndex" class="d-flex justify-content-between align-items-center">
                                            <span><strong>{{ detalle.grupo }}</strong></span>
                                            <b-badge variant="primary" pill class="badge-round">
                                                {{ formatCurrency(detalle.totalPorGrupo) }}
                                            </b-badge>
                                        </b-list-group-item>
                                    </b-list-group>
                                    <b-button @click="toggleDetails(index, 'sales')" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary">
                                        Cerrar detalles
                                    </b-button>
                                </div>
                            </td>
                        </tr>
                    </template>
					
					<tr role="row" v-if="sales.length === 0">
						<td role="cell"><strong>Ventas</strong></td>
						<td role="cell"><strong>0</strong></td>
                        <td role="cell"><strong>0</strong></td>
					</tr>
					<tr role="row" v-if="sales.length === 0">
						<td role="cell"><strong>Devoluciones</strong></td>
						<td role="cell"><strong>0</strong></td>
                        <td role="cell"><strong>0</strong></td>
					</tr>

					<!-- Otros Ingresos -->
					<template>
						<tr role="row">
                            <td role="cell"><strong>Otros Ingresos</strong></td>
                            <td role="cell"><strong>0</strong></td>
                            <td role="cell"><strong>0</strong></td>
                        </tr>
					</template>

					<!-- <tr role="row">
						<th colspan="2" role="cell" style="border-top: 1px solid black;"></th>
					</tr>

					<tr role="row" v-if="returnsValues.length === 0">
						<td role="cell"><strong>Devolución de Contado</strong></td>
						<td role="cell"><strong>0</strong></td>
					</tr>
					<tr role="row" v-if="returnsValues.length === 0">
						<td role="cell"><strong>Devolución de Crédito</strong></td>
						<td role="cell"><strong>0</strong></td>
					</tr> -->

					<!-- Devoluciones -->
					<!-- <template v-for="(item, index) in returnsValues">
                        <tr role="row" @click="toggleDetails(index, 'returns')" :key="`${index}-returns`">
                            <td :aria-colindex="`${index}-returns`" role="cell"><strong>Devolución Ventas de {{ item.metodoPago }}</strong></td>
                            <td :aria-colindex="`${index}-returns`" role="cell"><strong>{{ formatCurrency(item.totalPorMetodo) }}</strong></td>
                        </tr>
                        <tr v-if="item.showDetails" :key="`${index}-returns` + '-details'">
                            <td colspan="2">
                                <div class="details-container">
                                    <h4>Detalles de {{ item.metodoPago }}</h4>
                                    <b-list-group>
                                        <b-list-group-item v-for="(detalle, detalleIndex) in item.detallesPorFormaCobro" :key="detalleIndex" class="d-flex justify-content-between align-items-center">
                                            <span><strong>{{ detalle.formaCobro }}</strong></span>
                                            <b-badge variant="primary" pill class="badge-round">
                                                {{ formatCurrency(detalle.totalPorFormaCobro) }}
                                            </b-badge>
                                        </b-list-group-item>
                                    </b-list-group>
                                    <b-button @click="toggleDetails(index, 'returns')" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary">
                                        Cerrar detalles
                                    </b-button>
                                </div>
                            </td>
                        </tr>
                    </template> -->

					<!-- <tr role="row">
						<th colspan="2" role="cell" style="border-top: 1px solid black;"></th>
					</tr> -->

					<!-- Costo de lo vendido -->
					<template>
						<tr role="row" @click="costoVendidos.showDetails = !costoVendidos.showDetails">
                            <td role="cell"><strong>Costo de lo Vendido</strong></td>
                            <td role="cell"><strong>{{ formatCurrency(costoVendidos.sumatoriaTotal) }}</strong></td>
                            <td role="cell"><strong>0</strong></td>
                        </tr>
						<tr v-if="costoVendidos.showDetails" role="cell">
							<td colspan="3">
                                <div class="details-container">
                                    <h4>Detalles de Costo de lo vendido</h4>
                                    <b-list-group>
                                        <b-list-group-item v-for="(detalle, detalleIndex) in costoVendidos.detalles" :key="detalleIndex" class="d-flex justify-content-between align-items-center">
                                            <span><strong>{{ detalle.grupo }}</strong></span>
                                            <b-badge variant="primary" pill class="badge-round">
                                                {{ formatCurrency(detalle.totalPorGrupo) }}
                                            </b-badge>
                                        </b-list-group-item>
                                    </b-list-group>

									<b-button @click="costoVendidos.showDetails = false" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary">
                                        Cerrar detalles
                                    </b-button>
                                </div>
                            </td>
						</tr>
					</template>

					<template>
						<tr role="row" @click="purchases.showDetails = !purchases.showDetails">
                            <td role="cell"><strong>Compras</strong></td>
                            <td role="cell"><strong>{{ formatCurrency(purchases.sumatoriaTotal) }}</strong></td>
                            <td role="cell"><strong>{{ formatCurrency(purchases.sumatoriaImpuestos) }}</strong></td>
                        </tr>
						<tr v-if="purchases.showDetails" role="cell">
							<td colspan="3">
                                <div class="details-container">
                                    <h4>Detalles de Compras</h4>
                                    <b-list-group>
                                        <b-list-group-item v-for="(detalle, detalleIndex) in purchases.detalles" :key="detalleIndex" class="d-flex justify-content-between align-items-center">
											<span><strong>{{ detalle.grupo }}</strong></span>
                                            <b-badge :variant="'primary'" pill class="badge-round">
                                                {{ formatCurrency(detalle.totalPorGrupo) }}
                                            </b-badge>
                                        </b-list-group-item>
                                    </b-list-group>

									<b-button @click="purchases.showDetails = false" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary">
                                        Cerrar detalles
                                    </b-button>
                                </div>
                            </td>
						</tr>
					</template>

					<!-- Gastos -->
					<template>
						<tr role="row" @click="expensesValues.showDetails = !expensesValues.showDetails">
                            <td role="cell"><strong>Gastos</strong></td>
                            <td role="cell"><strong>{{ formatCurrency(expensesValues.sumatoriaTotal) }}</strong></td>
                            <td role="cell"><strong>{{ formatCurrency(expensesValues.sumatoriaImpuestos) }}</strong></td>
                        </tr>
						<tr v-if="expensesValues.showDetails" role="cell">
							<td colspan="3">
                                <div class="details-container">
                                    <h4>Detalles de Gastos por Tipo Proveedor</h4>
                                    <b-list-group>
                                        <b-list-group-item v-for="(detalle, detalleIndex) in expensesValues.detalles" :key="detalleIndex" class="d-flex justify-content-between align-items-center">
											<span><strong>{{ detalle.tipoProveedor }}</strong></span>
                                            <b-badge :variant="changeByCogs && detalle.tipoProvId === zInventory ? 'secondary' : 'primary'" pill class="badge-round">
                                                {{ formatCurrency(detalle.totalPorTipoProveedor) }}
                                            </b-badge>
                                        </b-list-group-item>
                                    </b-list-group>

									<b-button @click="expensesValues.showDetails = false" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary">
                                        Cerrar detalles
                                    </b-button>
                                </div>
                            </td>
						</tr>
					</template>
                </tbody>
                <tfoot>
                    <tr></tr>
                        <th class="font-weight-bold text-right">
							<h5>TOTAL</h5>
						</th>
                        <th class="font-weight-bold">
                           <h6>{{ formatCurrency(grandTotal) }}</h6>
                        </th>
                        <th class="font-weight-bold"></th>
                    </tr>
                </tfoot>
            </table>
        </div>

        <!-- <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="scrollable-container">
            <app-collapse accordion type="border">
                <app-collapse-item v-for="(item, index) in sales" :key="index" :title="item.tipoDocto" :badge="item.totalPorTipoDocto" badgeVariant="primary">
                    {{ item }}
                </app-collapse-item>
            </app-collapse>
        </vue-perfect-scrollbar> -->

        
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormCheckbox,
  BFormRadio,
  BFormRadioGroup,
  BFormTextarea,
  BFormInvalidFeedback,
  BButton,
  BOverlay,
  BTable,
  BTooltip,
  VBTooltip,
  BListGroup, 
  BListGroupItem,
  BBadge,
  BLink,
} from "bootstrap-vue";

import flatPickr from "vue-flatpickr-component";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import * as helper from "@/libs/helpers";
import vSelect from "vue-select";
import { heightTransition } from "@core/mixins/ui/transition";
import moment from "moment";
import store from "@/store";
import { computed, nextTick, onMounted, ref } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'


import DashboardsProvider from "@/providers/Dashboards";
const DashboardsResource = new DashboardsProvider();

export default {
  name: "IncomeStatement",
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BFormRadio,
    BFormRadioGroup,
    BFormTextarea,
    BFormInvalidFeedback,
    BButton,
    BOverlay,
    BTable,
    BTooltip,
    VBTooltip,
    BListGroup, 
    BListGroupItem,
    BBadge,
    BLink,

    //
    AppCollapse,
    AppCollapseItem,

    //
    VuePerfectScrollbar,
    flatPickr,
    vSelect,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  mixins: [heightTransition],
  setup() {
    const loading = ref(false);
    const perfectScrollbarSettings = ref({
        maxScrollbarLength: 60,
        wheelPropagation: false,
    })
    const filter = ref({
      fechaIni: moment().startOf("month").format("YYYY-MM-DD"),
      fechaFin: moment().format("YYYY-MM-DD"),
      almacenId: 0,
    });
    const fields = ref([
        { key: 'show_details', label: '' },
        { key: 'metodoPago', label: 'Concepto' },
        { key: 'totalPorMetodo', label: 'Importe' }
    ])
    const sales = ref([]);
	const costoVendidos = ref({
		sumatoriaTotal: 0,
		detalles: [],
		showDetails: false,
	});
	const expensesValues = ref({
		sumatoriaTotal: 0,
		detalles: [],
		showDetails: false,
	});
	const returnsValues = ref([]);
	const otherIncomes = ref({
		sumatoriaTotal: 0,
		detalles: [],
		showDetails: false,
	});
	const purchases = ref({
		sumatoriaTotal: 0,
		detalles: [],
		showDetails: false,
	});
	const changeByCogs = ref(false);

    const warehouses = computed (() => {
        return [{almacen_Id: 0, nombre: 'TODOS'}, ...store.getters["auth/getWareHouses"]];
    });

	const grandTotal = computed(() => {
        const incomes = sales.value.filter(v => v.tipoDocto === "Ventas")
            .reduce((carry, item) => carry + item.totalPorTipoDocto, 0) || 0;

        const returnsTotal = sales.value.filter(v => v.tipoDocto === "Devoluciones")
            .reduce((carry, item) => carry + item.totalPorTipoDocto, 0) || 0;

        const compras = purchases.value?.sumatoriaTotal || 0;
        const costoVendido = costoVendidos.value?.sumatoriaTotal || 0;
        const expensesTotal = expensesValues.value?.sumatoriaTotal || 0;

        if (changeByCogs.value) {
            return incomes - costoVendido - returnsTotal - expensesTotal;
        }
        return incomes - returnsTotal - compras;
    });

	const setting = computed(() => {
		return store.getters["auth/getSetting"];
	});

	const zInventory = computed(() => {
		return parseInt(setting.value.CLAVE_TIPO_PROVEEDOR_Z_INVENTARIOS);
	});

	const grandTotalExpenses = computed(() => {
		const total = expensesValues.value.sumatoriaTotal;
		if (changeByCogs.value) {
			return total - totalOnlyInventories.value;
		}
		return total;
	});

	const totalOnlyInventories = computed(() => {
		const onlyInventories = expensesValues.value.detalles.filter((item) => item.tipoProvId === zInventory.value);
		if (onlyInventories.length > 0 && changeByCogs.value) {
			return onlyInventories.reduce((carry, item) => carry + item.totalPorTipoProveedor, 0);
		}
		return 0
	});	

    const toggleDetails = (index, type = 'sales') => {
		if (type === 'sales') {
			sales.value.forEach((item, i) => {
				if (i !== index) {
					item.showDetails = false;
				}
			});
		
			// Alternar la visibilidad de los detalles de la fila seleccionada
			sales.value[index].showDetails = !sales.value[index].showDetails;
		} else {
			returnsValues.value.forEach((item, i) => {
				if (i !== index) {
					item.showDetails = false;
				}
			});
		
			// Alternar la visibilidad de los detalles de la fila seleccionada
			returnsValues.value[index].showDetails = !returnsValues.value[index].showDetails;
		}
    }

	const getSales = async () => {
		loading.value = true;
		try {
			const { data } = await DashboardsResource.getIncomeStatements(filter.value);
			if (data.isSuccesful) {

    			sales.value = data.data.ventasPorGrupos.map((item) => {
					return {
						...item,
						showDetails: false,
					};
				});

				costoVendidos.value = {
					...data.data.costoVendidos,
					showDetails: false,
				}

                purchases.value = {
					...data.data.compras,
					showDetails: false,
				}

				expensesValues.value = {
					...data.data.gastos,
					showDetails: false,
				}

								

				// returnsValues.value = data.data.devolucionesPorMetodoPago.map((item) => {
				// 	return {
				// 		...item,
				// 		showDetails: false,
				// 	};
				// });

                // const resultados = {
                //     ventas: sales.value,
                //     compras: purchases.value,
                //     gastos: expensesValues.value,
                //     devoluciones: returnsValues.value,
                //     costoVendidos: costoVendidos.value,
                // };
                // console.log(resultados);


			} else {
				helper.danger(data.message);
			}
			console.log(sales.value);
		} catch (error) {
			helper.handleResponseErrors(error);
		} finally {
			loading.value = false;
		}
	};

    return {
      loading,
      filter,
      fields,
      sales,
	  costoVendidos,
	  expensesValues,
	  returnsValues,
	  changeByCogs,
	  purchases,

	  //Computed
      warehouses,
	  zInventory,
	  grandTotal,
	  grandTotalExpenses,
	  totalOnlyInventories,
      perfectScrollbarSettings,

      // methods
      toggleDetails,
      getSales,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-sweetalert.scss";
@import "~@core/scss/vue/libs/vue-select.scss";

.scrollable-container {
    max-height: calc(100vh - 130px) !important;
    overflow-y: auto; /* Asegura el scroll */
}
</style>

<style>
.details-container {
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  margin-top: 10px;
}

.details-container h4 {
  margin-bottom: 10px;
}

.details-container ul {
  list-style-type: none;
  padding-left: 0;
}

.details-container li {
  margin-bottom: 5px;
}

.list-group-item {
    padding: 0.20rem!important;
    background-color: #f9f9f9!important;
    border: 0px solid rgba(34, 41, 47, 0.125);
}


/* .table-no-inner-borders td, */
.table-no-inner-borders td {
	border: none!important;
}
.table-no-outer-border {
        /* border: none !important;; */
    }
</style>
