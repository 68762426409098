<template>
    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
        <b-card no-body>
            <b-card-header>
                <b-card-title>Lista de Bitácoras</b-card-title>
            </b-card-header>

            <b-card-body>
                <b-row>
                    <b-col cols="12" md="3">
                        <b-form-group label="Fecha Inicio" label-for="date_start">
                            <flat-pickr v-model="filter.fechaIni" id="date_start"
                                class="form-control flatpickr-small" :config="{ dateFormat: 'Y-m-d' }"
                                placeholder="DD/MM/YYYY" />
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" md="3">
                        <b-form-group label="Fecha Fin" label-for="date_start">
                            <flat-pickr v-model="filter.fechaFin" id="date_end"
                                class="form-control flatpickr-small" :config="{ dateFormat: 'Y-m-d' }"
                                placeholder="DD/MM/YYYY" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3"></b-col>

                    <b-col cols="12" md="10">
                        <v-select id="type" v-model="filter.search" :options="binnaclesTypes"
                            label="nombre" :reduce="option => option.nombre" :clearable="false"
                            class="select-size-sm"
                            placeholder="Selecciona un tipo de bitácora">
                        </v-select>
                    </b-col>

                    <b-col cols="12" md="2" class="mt-0">
                        <b-button :disabled="loading" block @click="getBinnacles" size="sm" variant="primary" class="mr-1">
                            Buscar
                        </b-button>
                    </b-col>
                </b-row>
            </b-card-body>

            <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="scrollable-container">
                <app-collapse accordion type="border">
                    <app-collapse-item v-for="(item, index) in binnacles" :key="index" :title="index" :badge="item.length"
                    :badge-variant="`light-primary`">
                        <table class="table table b-table table-sm b-table-no-border-collapse b-table-selectable b-table-select-single w-100 text-small small">
                            <thead>
                                <tr>
                                    <th style="font-size: 10px!important;">Fecha</th>
                                    <th style="font-size: 10px!important;">Hora</th>
                                    <th style="font-size: 10px!important;">Evento</th>
                                    <th style="font-size: 10px!important;" class="text-center">Usuario</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(binnacle, idx) in item" :key="index+idx">
                                    <td class="text-capitalize">{{ customDate(binnacle.fecha, 'DD MMMM, YYYY') }}</td>
                                    <td>{{ binnacle.hora }}</td>
                                    <td>{{ binnacle.tipo_Evento }}</td>
                                    <td>{{ binnacle.usuario }}</td>
                                    <td>
                                        <b-button size="sm" variant="outline-primary" @click="showDetail(binnacle)">
                                            <feather-icon icon="EyeIcon" />
                                        </b-button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </app-collapse-item>
                </app-collapse>

                <div v-if="Object.keys(binnacles).length === 0">
                    <b-card-body>
                        <b-alert variant="warning" show>
                            <div class="alert-body">
                                <feather-icon icon="AlertTriangleIcon" class="mr-50" />
                                <span>No se encontraron registros</span>
                            </div>
                        </b-alert>
                    </b-card-body>
                </div>
            </vue-perfect-scrollbar>

            <!-- <div ref="tableContainer" class="table-responsive" :class="`${binnacles.length >= 10 ? 'b-table-sticky-header' : ''}`">
                <table class="table table b-table table-sm b-table-no-border-collapse b-table-selectable b-table-select-single w-100 text-small small">
                    <thead>
                        <tr>
                            <th style="font-size: 10px!important;">Tipo Bitácora</th>
                            <th style="font-size: 10px!important;">Fecha</th>
                            <th style="font-size: 10px!important;">Hora</th>
                            <th style="font-size: 10px!important;">Evento</th>
                            <th style="font-size: 10px!important;" class="text-center">Usuario</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody v-if="binnacles.length > 0">
                        <tr v-for="(item, index) in binnacles" :key="index">
                            <td>{{ item.descripcion_Corta }}</td>
                            <td class="text-capitalize">{{ customDate(item.fecha, 'DD MMMM, YYYY') }}</td>
                            <td>{{ item.hora }}</td>
                            <td>{{ item.tipo_Evento }}</td>
                            <td>{{ item.usuario }}</td>
                            <td>
                                <b-button size="sm" variant="outline-primary" @click="showDetail(item)">
                                    <feather-icon icon="EyeIcon" />
                                </b-button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="7" class="text-center">No hay registros</td>
                        </tr>
                    </tbody>
                </table>
            </div> -->

            <!-- Pagination -->
            <!-- <div v-if="binnacles.length > 0" class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">{{ resolvePaginationTranslate(dataMeta) }}</span>
                    </b-col>
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <div class="mt-2 demo-spacing-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="pageSize"/>
                        </div>
                    </b-col>
                </b-row>
            </div> -->
        </b-card>


        <!-- Modal -->
        <b-modal id="modalDetail" ref="modalDetail" ok-only ok-title="Cerrar" centered :size="binnacle.bitacorasDetalles && binnacle.bitacorasDetalles.length > 0 ? 'lg': 'sm'" title="Detalle Bitácora"
        no-close-on-backdrop @cancel="isActiveModal = false" @hidden="closeModal" :visible="isActiveModal">
            <b-card-text v-html="binnacle.descripcion"></b-card-text>

            <b-table v-if="binnacle.bitacorasDetalles && binnacle.bitacorasDetalles.length > 0" striped hover :items="binnacle.bitacorasDetalles" :fields="fields" :busy="loading" class="mt-2 text-small small"
            small>

            <template #cell(precio_Total_Neto)="data">
                <span>{{ formatCurrency(data.item.precio_Unitario_Impto - (data.item.precio_Unitario_Impto * (data.item.pctje_Dscto / 100))) }}</span>
            </template>
                
            </b-table>
        </b-modal>

      
    </b-overlay>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BOverlay, BSpinner, BTable, BFormCheckbox, BFormGroup, BFormInput,
    BModal, VBModal, BSidebar, BForm, BIcon, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BFormTags, BAvatar, BPagination, BFormSelect,
    BBadge, BCardSubTitle, BCardTitle, BTabs, BTab, BListGroup, BListGroupItem
} from 'bootstrap-vue'
import Swal from "sweetalert2";
import "animate.css";
import flatPickr from "vue-flatpickr-component";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import * as helper from '@/libs/helpers';
import vSelect from "vue-select";
import { v4 as uuidv4 } from 'uuid';
import router from "@/router";
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'
import moment from 'moment';

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import BinnaclesProvider from '@/providers/Binnacles'
const BinnaclesResource = new BinnaclesProvider()

import DashboardsProvider from '@/providers/Dashboards'
const DashboardsResource = new DashboardsProvider()

export default {
    name: 'GlobalBinnacles',
    directives: {
        Ripple,
        'b-modal': VBModal,
        'b-toggle': VBToggle,
    },
    components: {
        BRow,
        BSidebar,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BCardText,
        BButton,
        BAlert,
        BLink,
        BOverlay,
        BSpinner,
        BTable,
        BTableLite,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BModal,
        VBModal,
        BForm,
        BIcon,
        BInputGroup,
        BInputGroupAppend,
        BInputGroupPrepend,
        BFormTextarea,
        BFormTags,
        BCardCode,
        BAvatar,
        BPagination,
        BFormSelect,
        BBadge,
        BCardSubTitle,
        BCardTitle,
        BTabs,
        BTab,
        AppCollapse,
        AppCollapseItem,
        BListGroup, 
        BListGroupItem,
        
        //
        VuePerfectScrollbar,      
        flatPickr,
        vSelect,
    },
    mixins: [heightTransition],
    data() {
        return {
           loading: false,
           filter: {
                fechaIni: moment().startOf('month').format('YYYY-MM-DD'),
                fechaFin: moment().format('YYYY-MM-DD'),
                tipoBitacora: 0,
                // tipoEvento: 'CO'
                pageSize: 100,
                pageNumber: 1,
                search: ''
            },
            fields: [
                { key: 'articulo', label: 'Articulo' }, 
                { key: 'clave_Articulo', label: 'Clave Articulo' },
                { key: 'unidad_Venta', label: 'Um' }, 
                // { key: 'ultimo_Costo', label: 'Ult. Costo' }, 
                { key: 'unidades', label: 'Unidades' }, 
                { key: 'precio_Unitario_Impto', label: 'Precio' },
                { key: 'pctje_Dscto', label: '% Desc' },                
                { key: 'precio_Total_Neto', label: 'Total' }
            ],
            binnacles: [],
            binnacle: {
                description: '',
                bitacorasDetalles: []
            },
            pageSize: 100,
            currentPage: 1,
            totalRecords: 0,
            binnaclesTypes: [],
            isActiveModal: false,
            folio: '',
            perfectScrollbarSettings: {
                maxScrollbarLength: 60,
                wheelPropagation: false,
            }
        }
    },
    computed: {
        dataMeta() {
            const localItemsCount = _.size(this.binnacles);
            return {
                from:
                    this.pageSize * (this.currentPage - 1) +
                    (localItemsCount ? 1 : 0),
                to: this.pageSize * (this.currentPage - 1) + localItemsCount,
                of: this.totalRecords,
            };
        },
        formattedFolio() {
            const regex = /Folio=([A-Za-z0-9]+)/; // Busca "Folio=" seguido de letras o números
            const match = this.binnacle.descripcion_Larga.match(regex);
            if (match) {
                const result = match[1]; // Captura el valor del folio sin "Folio="

                // Verificar que las primeras dos letras sean alfabéticas
                let prefix = result.slice(0, 2).replace(/[^A-Za-z]/g, 'A'); // Reemplaza si no es letra
                let numericPart = result.slice(2).replace(/[^0-9]/g, ''); // Solo permite números en la parte numérica

                // Concatenar el prefijo y rellenar la parte numérica con ceros a la derecha para que en total tenga 9 caracteres
                return (prefix + numericPart.padStart(7, '0')).slice(0, 9); // Ajuste total de longitud a 9
                
            } else {
                return '';
            }
        },
    },
    created () {
        // Crear el nuevo objeto de almacén
        const allBinnaclesTypeOption = {
            tipo_Bitacora_Id: 0,
            clave: '',
            nombre: 'TODAS'
        };

        this.binnaclesTypes.push(allBinnaclesTypeOption);
    },
    async mounted() {        
        await this.getBinnaclesTypes();
    },
    methods: {
        closeModal() {            
            this.isActiveModal = false;
            this.binnacle = {};
            this.$refs.modalDetail.hide();
        },
        async showDetail(item) {

            const descripcion = item.descripcion_Larga.split('\n').join('<br>');
            this.binnacle = {...item, descripcion: descripcion};
            this.folio = this.formattedFolio;
            this.loading = true;

            // try {
            //     const { data } = await BinnaclesResource.getDetailBinnacle(item.id);
            //     this.loading = false;

            //     if (data.isSuccesful) {
                    
            //         this.binnacle = {...this.binnacle, ...data.data};
            //         this.isActiveModal = true;
            //         this.$refs.modalDetail.show();

            //     } else {
            //         this.danger(data.message);
            //     }

            //     console.log(this.binnacle);

            // }catch (error) {
            //     this.loading = false;
            //     this.handleResponseErrors(error);
            // }
            this.$swal.fire({
                title: 'Detalle de Bitácora',
                html: descripcion,
                // icon: 'info',
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonText: 'Cerrar',
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                showClass: {
                    popup: "animate__animated animate__flipInX",
                },
                buttonsStyling: false,
            }).then((result) => {
                this.loading = false;
            });
        },
        async getBinnaclesTypes () {
            try {
                this.loading = true;
                const { data } = await BinnaclesResource.getBinnaclesType();
                this.loading = false;
                if (data.isSuccesful) {
                    data.data.map(item => {
                        this.binnaclesTypes.push(item);
                    });
                }
            } catch (error) {
                this.loading = false;
                this.handleResponseErrors(error);
            } finally {
                this.loading = false;
            }

        },
        async getBinnacles() {
            this.binnacles = []
            try {
                this.loading = true;
                this.filter.pageSize = this.pageSize;
                this.filter.pageNumber = this.currentPage;
                this.filter.tipoBitacora = this.findSetting('TIPO_BITACORAS_AUTORIZACION') || 0;

                const { data } = await DashboardsResource.getAllBinnacles(this.filter);
                this.loading = false;
                if (data.isSuccesful) {
                    this.binnacles = _.groupBy(data.data.map(item => {
                        return {
                            ...item,
                            descripcion_Larga: item.descripcion_Larga.split('\n').join('<br>'),
                        }
                    }), 'descripcion_Corta')                    
                } else {
                    this.danger(data.message);
                }
            } catch (error) {
                this.loading = false;
                this.handleResponseErrors(error);
            }finally {
                this.loading = false;
            }

        }
    },
    watch:{
        currentPage(value) {
            this.getBinnacles();
        },
        pageSize(value) {
            this.getBinnacles();
        },
    }

}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-sweetalert.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
@import '~@core/scss/vue/libs/vue-flatpicker.scss';

.scrollable-container {
    max-height: calc(100vh - 130px) !important;
    overflow-y: auto; /* Asegura el scroll */
}

</style>
