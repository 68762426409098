<template>
    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
        <b-row class="match-height">
            <b-col xl="3" md="3" sm="6">
                <statistic-card-vertical icon="ShoppingBagIcon" color="info" :statistic="sale.total_Ticket" statistic-title="Total Tickets" />
            </b-col>
            <b-col xl="3" md="3" sm="6">
                <statistic-card-vertical icon="AwardIcon" color="warning" :statistic="sale.ticket_Promedio" statistic-title="Valor Tickets Promedio" />
            </b-col>
            <b-col xl="3" md="3" sm="6">
                <statistic-card-vertical icon="UsersIcon" color="warning" :statistic="sale.total_Clientes" statistic-title="Total Clientes" />
            </b-col>
            <b-col xl="3" md="3" sm="6">
                <statistic-card-vertical icon="DollarSignIcon" color="primary" :statistic="sale.venta_Importe" statistic-title="Venta Neta"/>
            </b-col>
        </b-row>
        <!-- <b-row class="match-height">
            <b-col lg="3" sm="6">
                <statistic-card-horizontal
                    icon="FileTextIcon"
                    :statistic="sale.total_Ticket"
                    statistic-title="Total Tickets"
                    />
            </b-col>

            <b-col lg="3" sm="6">
                <statistic-card-horizontal
                    icon="FileTextIcon"
                    color="info"
                    :statistic="sale.ticket_Promedio"
                    statistic-title="Tickets Promedio"
                    />
            </b-col>

            <b-col lg="3" sm="6">
                <statistic-card-horizontal
                    icon="UsersIcon"
                    color="warning"
                    :statistic="sale.total_Clientes"
                    statistic-title="Total Clientes"
                    />
            </b-col>

            <b-col lg="3" sm="6">
                <statistic-card-horizontal
                    icon="DollarSignIcon"
                    color="success"
                    :statistic="sale.venta_Importe"
                    statistic-title="Venta Neta"
                    />
            </b-col>
        </b-row> -->
    </b-overlay>
</template>

<script>
import {
    BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BOverlay
} from 'bootstrap-vue'

import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

import { kFormatter } from '@core/utils/filter'
import DashboardsProvider from '@/providers/Dashboards'
const DashboardsResource = new DashboardsProvider()

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardTitle,
        BCardText,
        BCardBody,
        BMedia,
        BAvatar,
        BMediaAside,
        BMediaBody,
        BOverlay,

        //
        StatisticCardVertical,
        StatisticCardHorizontal,
    },
    props: {
        filterBy: {
            type: Object,
            required: true,
        },
        refresh: {
            type: Boolean,
            default: () => false,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            sale: {
                vendedor_Id: 0,
                vendedor: null,
                total_Ticket: 0,
                total_Clientes: 0,
                porcentaje: 0,
                venta_Importe: 0,
            },
            statisticsItems: [
                {
                    icon: 'TrendingUpIcon',
                    color: 'light-primary',
                    title: '230k',
                    subtitle: 'Sales',
                    customClass: 'mb-2 mb-xl-0',
                },
                {
                    icon: 'UserIcon',
                    color: 'light-info',
                    title: '8.549k',
                    subtitle: 'Customers',
                    customClass: 'mb-2 mb-xl-0',
                },
                {
                    icon: 'BoxIcon',
                    color: 'light-danger',
                    title: '1.423k',
                    subtitle: 'Products',
                    customClass: 'mb-2 mb-sm-0',
                },
                {
                    icon: 'DollarSignIcon',
                    color: 'light-success',
                    title: '$9745',
                    subtitle: 'Revenue',
                    customClass: '',
                },
            ]
        }
    },
    computed: {
        isLoadDashboard() {
            return this.findSetting('ACTIVAR_CARGA_DASHBOARD') || false
        }
    },
    async mounted() {
        if (this.isLoadDashboard) {
            await this.getSalesStatistic()
        }
    },
    methods: {
        kFormatter,
        async getSalesStatistic() {

            this.sale = {
                vendedor_Id: 0,
                vendedor: null,
                total_Ticket: 0,
                ticket_Promedio: 0,
                total_Clientes: 0,
                venta_Importe: 0,
                porcentaje: 0,
            }

            const query = {
                ...this.filterByDates(this.filterBy.key),
                vendedorId: this.filterBy.vendedor_Id,
            }

            if (this.filterBy && this.filterBy.key) {
                this.loading = true
                const { data } = await DashboardsResource.getSalesStatistic(query)
                this.loading = false
                if (data.isSuccesful) {
                    this.sale = {
                        ...data.data,
                        total_Ticket: kFormatter(data.data.total_Ticket),
                        ticket_Promedio: kFormatter(data.data.ticket_Promedio),
                        total_Clientes: kFormatter(data.data.total_Clientes),
                        venta_Importe: kFormatter(data.data.venta_Importe),
                    }
                }
            }
        }
    },
    watch: {
        refresh(value) {
            if (value) {
                this.getSalesStatistic()
            }
        },
        filterBy(value) {
            this.getSalesStatistic()
        }
    }
}
</script>