<template>
    <div>
        <!-- Modal Search Customer -->
        <b-modal id="modal-search-customer" ref="modal-search-customer" title="Búsqueda de Clientes" @hidden="close"
            ok-only ok-title="Aceptar" size="lg" no-close-on-backdrop>

            <div class="m-2">
                <b-row>
                    <b-col cols="12" md="12">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-input-group>
                                <b-form-input size="sm" v-model="searchQuery" placeholder="Buscar por nombre" 
                                @keyup.enter="searchCustomers" autocomplete="off" />
                                <b-input-group-append>
                                <b-button @click="searchCustomers" size="sm" variant="primary">
                                    <feather-icon icon="SearchIcon" /> Buscar
                                </b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </div>
                    </b-col>
                </b-row>
            </div>

            <!-- Table -->
            <div class="table-responsive">
                <div class="position-relative b-table-responsive mb-0 text-small small table-responsive"
                            :class="`${customers.length > 10 ? 'b-table-sticky-header' : ''}`">
                    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
                        <table ref="articlesTable" 
                        class="table b-table table-sm b-table-no-border-collapse text-small small b-table-small b-table-selectable b-table-select-single">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Clave Cliente</th>
                                    <th>Nombre</th>
                                    <th>Límite Crédito</th>
                                    <th>RFC</th>
                                    <th>Teléfono</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in customers" :key="index" @click="rowSelected(item, index)"
                                    :class="{ 'selected-row': isSelected(index) }">
                                    <td>
                                        <b-button size="sm" @click="chooseCustomer(item)" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                            variant="outline-primary">
                                            Elegir
                                        </b-button>
                                    </td>
                                    <td>{{ item.clave_Cliente }}</td>
                                    <td>{{ item.nombre }}</td>
                                    <td>{{ formatCurrency(item.limite_Credito) }}</td>
                                    <td>{{ item.rfc_Curp }}</td>
                                    <td>{{ item.telefono1 }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </b-overlay>
                </div>
            </div>            

            <!-- Pagination -->
            <div v-if="totalRecords > 0" class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">{{ resolvePaginationTranslate(dataMeta) }}</span>
                    </b-col>
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <div class="mt-2 demo-spacing-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="pageSize"/>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </b-modal>
    </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
    BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BOverlay, BSpinner, BTable, BFormCheckbox, BFormGroup, BFormInput,
    BModal, VBModal, BSidebar, BForm, BIcon, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BFormTags, BAvatar,
    BPagination,
} from 'bootstrap-vue'
import router from "@/router";
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'

import CustomersProvider from "@/providers/Customers"
const CustomersResource = new CustomersProvider()

export default {
    name: 'SearchCustomers',
    directives: {
        Ripple,
        'b-modal': VBModal,
        'b-toggle': VBToggle,
    },
    components: {
        BRow,
        BSidebar,
        BCol,
        BCard,
        BCardBody,
        BCardText,
        BButton,
        BAlert,
        BLink,
        BOverlay,
        BSpinner,
        BTable,
        BTableLite,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BModal,
        VBModal,
        BForm,
        BIcon,
        BInputGroup,
        BInputGroupAppend,
        BInputGroupPrepend,
        BFormTextarea,
        BFormTags,
        BCardCode,
        BAvatar,
        BPagination,
    },
    mixins: [heightTransition],
    props: ["active"],
    data() {
        return {
            loading: false,
            customers: [],
            columns: [
                { key: "actions", label: ' ' },
                {
                    key: 'clave_Cliente',
                    label: 'Clave Cliente',
                    tdClass: 'text-small',
                },
                {
                    key: "nombre",
                    label: 'Nombre',
                    tdClass: 'text-small',
                },
                {
                    key: "limite_Credito",
                    label: "Límite de Crédito",
                    tdClass: 'text-small',
                },
                {
                    key: "rfc_Curp",
                    label: 'RFC - CURP',
                    tdClass: 'text-small',
                },
                {
                    key: "telefono1",
                    label: 'Teléfono',
                    tdClass: 'text-small',
                },
            ],
            table: null,
            searchQuery: null,
            selectedRow: null,
            refCustomerListTable: null,
            selectableTable: null,
            currentPage: 1,
            pageSize: 10,
            totalRecords: 0,
            selectedRowIndex: -1,
        }
    },
    computed: {
        dataMeta() {
            const localItemsCount = _.size(this.customers);
            return {
                from:
                    this.pageSize * (this.currentPage - 1) +
                    (localItemsCount ? 1 : 0),
                to: this.pageSize * (this.currentPage - 1) + localItemsCount,
                of: this.totalRecords,
            };
        },
    },
    mounted() {
       
    },
    methods: {
        isSelected(index) {
            return index === this.selectedRowIndex;
        },
        rowSelected(item, index) {
            this.$emit("selected", { ...item });
            this.close();
        },
        toggleDetails(item) {
            this.$emit("selected", { ...item });
            this.close();
        },
        onRowClicked(row) {
            this.selectedRow = row
        },
        chooseCustomer(item) {
            this.$emit("selected", { ...item });
            this.close();
        },
        selectFirstRow(){
            // this.$refs.selectableTable.selectRow(0)
        },
        async searchCustomers() {
            this.selectedRowIndex = -1
            // if (!this.searchQuery) return false //this.danger(`Debe ingresar el nombre del cliente a buscar`)

            const query = {
                searchText: this.searchQuery,
                pageSize: this.pageSize,
                currentPage: this.currentPage,
            }

            try {
                this.loading = true
                const { data } = await CustomersResource.getAll(query)
                this.loading = false
                if (data.isSuccesful) {
                    this.customers = data.data
                    this.totalRecords = data.totalRecords
                } else {
                    this.danger(data.message)
                }
            }catch(e) {
                this.handleResponseErrors(e)
            }finally {
                this.loading = false
            }
            
        },
        close() {
            this.$refs['modal-search-customer'].hide()
            this.$emit("close");
        },
    },
    watch: {
        active(value) {
            if (value) {
                this.selectedRowIndex = -1
                this.totalRecords = 0
                this.currentPage = 1
                this.customers = []
                this.searchQuery = null
                this.$refs['modal-search-customer'].toggle()
            } else {
                this.close()
            }
        },
        currentPage(value) {
            this.searchCustomers();
        },
        // pageSize(value) {
        //     this.searchCustomers();
        // },
    }
}
</script>

<style>
.selected-row {
    background-color: #007bff; /* Color de fondo para la fila seleccionada */
    color: white; /* Color de texto para la fila seleccionada */
}
</style>