<template>
    <b-card-actions ref="cardBinnacle" :title="`Bitácoras ${currentDate('DD MMMM Y')}`" action-refresh @refresh="getBinnacles">
        <b-row>
            <b-col cols="12">
                <vue-perfect-scrollbar                 
                    ref="scrollContainerSearch"
                    :settings="perfectScrollbarSettings" 
                    class="scrollable-container media-list scroll-area">
                    <app-timeline>
                        <app-timeline-item v-for="(item, index) in binnacles" :key="index" :fillBorder="true"
                        :variant="getColorOnBinnale(item.tipo)">
                            <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                <h6 class="h6">{{ item.tipo }}</h6>
                                <small class="timeline-item-time text-nowrap text-muted ml-1">{{ customDate(item.fecha_Actualizacion, 'HH:mm:ss a') }}</small>
                            </div>
                            <small class="text-small">{{ item.descripcion }}</small>
                            <b-media no-body>
                                <b-media-body class="my-auto">
                                    <small class="media-body mb-0"><strong>Creado Por:</strong> {{ item.nombre_Usuario }}</small>                                    
                                </b-media-body>
                            </b-media>
                        </app-timeline-item>                    
                    </app-timeline>
                </vue-perfect-scrollbar>  
            </b-col>
        </b-row>
    </b-card-actions>
</template>

<script>
import {
    BRow, BCol,
    BCard, BCardBody, BCardHeader, BCardTitle, BImg, BMedia, BMediaBody, BMediaAside, BAvatar, BAvatarGroup, VBTooltip,
    BOverlay, BSpinner, BButton
} from 'bootstrap-vue'

import store from '@/store'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

import { kFormatter } from '@core/utils/filter'
import DashboardsProvider from '@/providers/Dashboards'
import FeatherIcon from '../../feather-icon/FeatherIcon.vue'
const DashboardsResource = new DashboardsProvider()

/* eslint-disable global-require */
export default {
    name: 'Binnacle',
    components: {
        BRow, 
        BCol,
        BCard,
        BImg,
        BCardBody,
        BCardHeader,
        BCardTitle,
        AppTimeline,
        AppTimelineItem,
        BMedia,
        BAvatar,
        BMediaBody,
        BMediaAside,
        BAvatarGroup,
        BOverlay, 
        BSpinner,
        BButton,
        
        //
        VuePerfectScrollbar,
        FeatherIcon,
        BCardActions,
    },
    directives: {
        'b-tooltip': VBTooltip,
    },
    data () {
        return {
            loading: false,
            binnacles: [],
            perfectScrollbarSettings: {
                maxScrollbarLength: 60,
                wheelPropagation: false,
            }
        }
    },
    computed: {
        isReaload() {
            return store.getters['routing/getIsRealodAbierta'] || store.getters['routing/getIsRealodEnRuta'] ||
                store.getters['routing/getIsRealoadCerrada'] || store.getters['routing/getIsRealodClientePasa']
        }
    },
    async mounted () {
        await this.getBinnacles()
    },
    methods: {
        kFormatter,
        async getBinnacles () {

            // Esperar a que Vue actualice el DOM
            await this.$nextTick()

            this.$refs['cardBinnacle'].showLoading = true
            try {
                const { data } = await DashboardsResource.getBinnacles()
                if (data.isSuccesful) {
                    this.binnacles = data.data
                }
            } catch (error) {
                this.$refs['cardBinnacle'].showLoading = false
                console.log(error)
            } finally {
                this.$refs['cardBinnacle'].showLoading = false
            }
        },
    },
    watch: {
        isReaload () {
            this.getBinnacles()
        }
    }
}
</script>

<style>
/* Estilos adicionales si es necesario */
.scrollable-container {
  max-height: 350px;
}
</style>