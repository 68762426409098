<template>
	<section id="dashboard-ecommerce">
		<b-row class="match-height">
			<b-col xl="8" lg="8" md="12">
				<customer-charges :sellers="sellers"/>
			</b-col>
		</b-row>

		
	</section>
</template>

<script>

import {
	BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'
import store from '@/store';
import { ref, onMounted, computed, watch, reactive, defineEmits, nextTick } from "@vue/composition-api";
import CustomerCharges from '@/@core/components/invoices/CustomerCharges.vue';

import DashboardsProvider from '@/providers/Dashboards'
const DashboardsResource = new DashboardsProvider()

export default {
	name: 'DashboardAdministrator',
	components: {
		BRow,
		BCol,
		BCard,
		BCardHeader,
		BCardTitle,
		BCardText,
		BCardBody,
		BMedia,
		BAvatar,
		BMediaAside,
		BMediaBody,

		//
        CustomerCharges,
		
	},
	props: {
		sellers: {
			type: Array,
			default: () => []
		},
	},
	setup() {

		const loading = ref(false)
		
		
		onMounted(async () => {
		
		})

		return {
			loading,
		}
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>