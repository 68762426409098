<template>
    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
        <b-card no-body>
            <b-card-header>
                <div>
                    <b-card-title>{{ isShowChart ? 'Top 10 Ventas Por Cliente' : 'Detalle de los 50 mejores clientes' }}</b-card-title>
                    <!-- <b-card-sub-title class="mb-0 text-capitalize">{{ selectedMonth }} {{ !isShowChart ? lastYear + '-' + currentYear : currentYear }}</b-card-sub-title> -->
                    <b-card-text class="font-small-2 text-capitalize">
                        {{ selectedMonth }} {{ !isShowChart ? lastYear + '-' + currentYear : currentYear }}
                    </b-card-text>
                </div>

                <b-dropdown variant="link" no-caret class="chart-dropdown" toggle-class="p-0">
                    <template #button-content>
                    <feather-icon icon="MoreVerticalIcon" size="22" class="text-body cursor-pointer"/>
                    </template>
                    <b-dropdown-item href="#" @click="changeView">
                        <span v-text="isShowChart ? ' Ver detalles' : ' Ver gráfica'" />
                    </b-dropdown-item>
                    <!-- <b-dropdown-item href="#" @click="setLastYear">
                       Ver Año {{ isLastYear ? (currentYear < myCurrentYear ? myCurrentYear : currentYear ) : lastYear }}
                    </b-dropdown-item> -->
                </b-dropdown>
            </b-card-header>
            <div v-show="sales.length > 0">
                <vue-apex-charts v-if="isShowChart && !loading && sales.length > 0" ref="donutChart" type="donut" :options="donutChart.chartOptions" :series="donutChart.series" />
                <div v-if="!isShowChart" ref="tableContainer" class="mb-0_ mt-1 table-responsive b-table-sticky-header">
                    <table class="table table b-table table-sm b-table-no-border-collapse b-table-selectable b-table-select-single w-100 text-small small">
                        <thead>
                        <tr>
                            <th>Cliente</th>
                            <th v-for="anio in anios" :key="anio">{{ anio }}</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr v-for="cliente in tablaDatos" :key="cliente.cliente_Id">
                                <td>{{ cliente.cliente }}</td>
                                <td v-for="anio in anios" :key="anio">
                                    {{ formatCurrency(cliente.ventasPorAnio[anio] || 0) }}
                                </td>
                            </tr>
                        </tbody>                       
                        <tfoot>
                            <tr>
                                <td class="font-weight-bold text-right">TOTAL</td>
                                <td v-for="anio in anios" :key="anio" class="font-weight-bold">
                                    {{ formatCurrency(tablaDatos.reduce((acc, cliente) => acc + cliente.ventasPorAnio[anio], 0)) }}
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div v-if="sales.length <= 0">
                <h5 class="text-center">No hay datos para mostrar.</h5>
            </div>
        </b-card>
    </b-overlay>
</template>

<script>
import store from '@/store'
import moment from 'moment'
import {
    BCard, BCardTitle, BCardSubTitle, BImg, BAvatar, BCardText, BCardHeader, BOverlay, BButton,
    BDropdown, BDropdownItem, BCardFooter, BCardBody, BRow, BCol
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { kFormatter } from '@core/utils/filter'

import DashboardsProvider from '@/providers/Dashboards'
import FeatherIcon from '../feather-icon/FeatherIcon.vue'
import { computed, onMounted, ref, watch } from '@vue/composition-api'
const DashboardsResource = new DashboardsProvider()

export default {
    components: {
        BCard,
        BCardTitle,
        BCardHeader,
        BCardSubTitle,
        BAvatar,
        BImg,
        BCardText,
        BOverlay,
        BButton,
        BDropdown, BDropdownItem,
        BCardFooter,
        BCardBody,
        BRow,
        BCol,
        VueApexCharts,

        VuePerfectScrollbar,
        BCardActions,
        FeatherIcon,
    },
    props: {
        refresh: {
            type: Boolean,
            default: () => false,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            sales: [],
            details: [],
            perfectScrollbarSettings: {
                maxScrollbarLength: 60,
                wheelPropagation: false,
            },
            donutChart: {
                series: [],
                chartOptions: {
                    legend: {
                        show: true,
                        position: 'bottom',
                        fontSize: '6px',
                        fontFamily: 'Montserrat',
                    },
                    colors: [
                        $themeColors.primary, $themeColors.warning, $themeColors.secondary, $themeColors.info, $themeColors.danger
                    ],
                    dataLabels: {
                        enabled: true,
                        fontSize: '6px',
                        fontFamily: 'Montserrat',
                        formatter(val) {
                            if (val) {
                                return `${parseInt(val)}%`
                            }
                        },
                    },
                    tooltip: {
                        enabled: true, // Habilita el tooltip                        
                        y: {
                            formatter(val) {
                                if (val) {
                                    return kFormatter(val);
                                }
                            }
                        }
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                labels: {
                                    show: false,
                                    name: {
                                        fontSize: '6px',
                                        fontFamily: 'Montserrat',
                                    },
                                    value: {
                                        fontSize: '6px',
                                        fontFamily: 'Montserrat',
                                        formatter(val, opts) {
                                            if (val) {
                                                return `${kFormatter(val)}`
                                            }
                                        },
                                    },
                                    total: {
                                        show: true,
                                        fontSize: '6px',
                                        fontFamily: 'Montserrat',
                                        label: 'Global',
                                        formatter(opts) {
                                            const global = opts.globals.seriesTotals.reduce((carry, i) => {
                                                return carry + i
                                            }, 0)
                                            if (global) {
                                                return kFormatter(global)
                                            }
                                        },
                                    },
                                },
                            },
                        },
                    },
                    labels: [],
                },
            },
            isShowChart: true,
            currentYear: 0,
            lastYear: 0,
            currentYearSales: [],
			lastYearSales: [],
			itemsMerges: [],
            anios: [],
            tablaDatos: [], // Datos procesados para la tabla
        }
    },
    computed: {
        isLoadDashboard(){
            return this.findSetting('ACTIVAR_CARGA_DASHBOARD') || false
        },
        processData () {
            return []
            // Agrupar por cliente_Id
            // const agrupado = ventas.reduce((acc, venta) => {
            //     const { cliente_Id, cliente, anio, venta_Importe } = venta;
            //     if (!acc[cliente_Id]) {
            //     acc[cliente_Id] = { cliente_Id, cliente, ventas: { 2023: 0, 2024: 0 } };
            //     }
            //     acc[cliente_Id].ventas[anio] = venta_Importe;
            //     return acc;
            // }, {});

            // // Convertir a un array y agregar suma total
            // const resultado = Object.values(agrupado).map(cliente => ({
            //     cliente_Id: cliente.cliente_Id,
            //     cliente: cliente.cliente,
            //     ventas: cliente.ventas,
            //     total: cliente.ventas[2023] + cliente.ventas[2024],
            // }));

            // // Ordenar por total de ventas descendente
            // return resultado.sort((a, b) => b.total - a.total);
        }, 
        myCurrentYear() {
            return moment().year()
        }, 
    },
    created() {
        
    },  
    beforeDestroy() {
        // window.removeEventListener('resize', this.updateChartData);
    },
    async mounted() {
        if (this.isLoadDashboard) {
            await this.getSalesCustomers()
        }
        // window.addEventListener('resize', this.updateChartData);
    },
    methods: {
        kFormatter,
        changeView() {
            this.isShowChart = !this.isShowChart

            // Forzar la actualización cuando se muestra el gráfico
            if (this.isShowChart) {
                this.$nextTick(() => {
                    this.updateChartData();  // Asegúrate de actualizar el gráfico cuando se vuelva a mostrar
                });
            }
        },
        updateChartData() {
            if (this.sales.length > 0) {
                this.$nextTick(() => {

                    const newSales = _.orderBy(this.sales.filter(s => s.anio == this.currentYear), ['venta_Importe'], ['desc'])

                    this.donutChart.chartOptions.labels = [];
                    this.donutChart.series = [];

                    const labels = newSales
                        .slice(0, 10)
                        .map(item => item.cliente)
                        .filter(label => typeof label === 'string' && label.trim() !== ''); // Filtrar solo strings válidos

                    const series = newSales
                        .slice(0, 10)
                        .map(item => item.venta_Importe)
                        .filter(value => !isNaN(value) && value > 0); // Filtrar números válidos

                    // Verifica si los datos filtrados son válidos
                    if (labels.length > 0 && series.length > 0 && this.$refs.donutChart) {
                        this.donutChart.chartOptions.labels = labels;
                        this.donutChart.series = series;

                        // Forzar la actualización del gráfico
                        this.$refs.donutChart.updateSeries(this.donutChart.series, true);
                        this.$refs.donutChart.updateOptions(this.donutChart.chartOptions, true, true, true);
                    } else {
                        console.error("Datos inválidos para el gráfico: ", labels, series);
                    }
                });
            }
        },
        prepararDatos(ventas) {
            // Obtener todos los años presentes
            this.anios = [...new Set(ventas.flatMap(v => v.ventasPorAnio.map(vp => vp.anio)))].sort();

            // Transformar los datos para la tabla
            this.tablaDatos = ventas.map(cliente => {
                const ventasPorAnio = this.anios.reduce((acc, anio) => {
                acc[anio] = cliente.ventasPorAnio.find(v => v.anio === anio)?.venta_Importe || 0;
                return acc;
                }, {});

                return { ...cliente, ventasPorAnio };
            }).slice(0, 50); // Limitar a 50 clientes
        },
        unificarVentasPorClientesOrdenados(ventas) {
            // Obtener todos los años presentes en los datos
            const anios = [...new Set(ventas.map(v => v.anio))];
            
            // Identificar el año anterior
            const anioAnterior = Math.max(...anios) - 1;

            // Agrupar por cliente_Id
            const clientesMap = {};
            ventas.forEach(venta => {
                const { cliente_Id, cliente, anio, venta_Importe } = venta;

                if (!clientesMap[cliente_Id]) {
                    clientesMap[cliente_Id] = { cliente_Id, cliente, ventas: {}, totalAnioAnterior: 0 };
                }

                clientesMap[cliente_Id].ventas[anio] = venta_Importe;

                // Sumar ventas del año anterior
                if (anio === anioAnterior) {
                    clientesMap[cliente_Id].totalAnioAnterior += venta_Importe;
                }
            });

            // Generar el arreglo final con los años normalizados
            const resultado = Object.values(clientesMap).map(cliente => {
                const ventasPorAnio = anios.map(anio => ({
                    anio,
                    venta_Importe: cliente.ventas[anio] || 0, // Si no hay ventas para ese año, setear 0
                }));

                return { 
                    cliente_Id: cliente.cliente_Id, 
                    cliente: cliente.cliente, 
                    ventasPorAnio, 
                    totalAnioAnterior: cliente.totalAnioAnterior,
                };
            });

            // Ordenar por total del año anterior (descendente)
            resultado.sort((a, b) => b.totalAnioAnterior - a.totalAnioAnterior);

            return resultado;
        },
        async getSalesCustomers() {
            this.sales = []

            const myDates = this.filterByDates(this.filter.key)
            if (this.isLastYear) {
                myDates.fechaIni = moment(myDates.fechaIni).subtract(1, 'year').format('YYYY-MM-DD')
                myDates.fechaFin = moment(myDates.fechaFin).subtract(1, 'year').format('YYYY-MM-DD')
            } else {
                myDates.fechaIni = moment(myDates.fechaIni).format('YYYY-MM-DD')
                myDates.fechaFin = moment(myDates.fechaFin).format('YYYY-MM-DD')
            }
            
            this.selectedMonth = moment(myDates.fechaIni).format('MMMM');
            this.currentYear = moment(myDates.fechaIni).year()
			this.lastYear = this.currentYear - 1
            const query = {
                ...myDates,
                vendedorId: this.filter.vendedor_Id
            }

            if (this.filter && this.filter.key) {
                this.loading = true
                const { data } = await DashboardsResource.getSalesCustomers(query)
                this.loading = false            
                if (data.isSuccesful && data.data != null && data.data.length > 0) {
                    this.sales = data.data;                   
                    this.itemsMerges = this.unificarVentasPorClientesOrdenados(this.sales)
                    this.prepararDatos(this.itemsMerges)

                    this.$nextTick(() => {
                        this.updateChartData();
                    });
                } else {
                    console.error("Datos inválidos de la API:", data);
                }
            }
        }
    },
    watch: {
        refresh(value) {
            if(value) {
                this.getSalesCustomers()
            }
        },
        filter(value) {
           this.getSalesCustomers()
        },
        isLastYear(value) {
            this.getSalesCustomers()
        }
    },
    setup(props, { emit }) {

        const selectedMonth = ref(null)
        const lastYear = ref(null)
        const currentYear = ref(null)
        const isLastYear = ref(false)

        const filter = computed(() => {
            const filter = store.getters['auth/getFilterOnDashboardSeller']
            const seller_id = store.getters['auth/getSellerId']
            return {
                ...filter,
                vendedor_Id: seller_id
            }
        })

        currentYear.value = filter.value.label.match(/\d+/g).map(Number)[0]
        lastYear.value = currentYear.value - 1

        watch(() => filter.value, (value) => {
            currentYear.value = value.label.match(/\d+/g).map(Number)[0]
            lastYear.value = currentYear.value - 1
        })

        const setLastYear = () => {
            isLastYear.value = !isLastYear.value
        }

        return {
            selectedMonth,
            isLastYear,
            lastYear,
            currentYear,

            //
            filter,

            setLastYear,
        }
    },
}
</script>
