import axios from '@/libs/axios'

export default class Dashboards {

    getAllTicketsByPaymentMethod (query) {
        return axios.get(`/v2/Dashboard/GetAllTicketsByPaymentMethod`, { params: query })
    }

    getIncomeStatements (query) {
        return axios.get(`/v2/Dashboard/GetIncomeStatements`, { params: query })
    }

    getPolicies (query) {
        return axios.get(`/v2/Dashboard/GetPolizas`, { params: query })
    }

    getDoctosPvDescuentos(query) {
        return axios.get(`/v2/Dashboard/GetDoctosPvDescuentos`, { params: query })
    }
    
    getStatisticRoutingByCurrentMonth() {
        return axios.get(`/v2/Dashboard/quantities-by-current-month`)
    }

    getTopChofer() {
        return axios.get(`/v2/Dashboard/top-chofer`)
    }

    //Bitacoras de la base central => Web_Bitacoras
    getBinnacles() {
        return axios.get(`/v2/Dashboard/binnacle`)
    }

    getStatisticRoutingByCurrentMonthByWareHouse() {
        return axios.get(`/v2/Dashboard/quantities-by-current-month-by-almacen`)
    }

    getStatisticRoutingByOperators() {
        return axios.get(`/v2/Dashboard/quantities-choferes`)
    }

    getTopSeller(query) {
        return axios.get(`/v2/Dashboard/TopSeller`, { params: query })
    }

    getSalesCustomers(query) {
        return axios.get(`/v2/Dashboard/GetSalesCustomers`, { params: query })
    }

    getSalesArticlesLines(query) {
        return axios.get(`/v2/Dashboard/GetSalesArticlesLines`, { params: query })
    }

    getSalesArticlesGroups(query) {
        return axios.get(`/v2/Dashboard/GetSalesArticlesGroups`, { params: query })
    }

    getSalesPaymentTypes(query) {
        return axios.get(`/v2/Dashboard/GetSalesPaymentTypes`, { params: query })
    }

    getSalesCustomerTypes(query) {
        return axios.get(`/v2/Dashboard/GetSalesCustomerTypes`, { params: query })
    }

    getSalesCustomerZones(query) {
        return axios.get(`/v2/Dashboard/GetSalesCustomerZones`, { params: query })
    }

    getSalesStatistic(query) {
        return axios.get(`/v2/Dashboard/GetSalesStatistic`, { params: query })
    }

    getSalesByMonthsAndDays(query) {
        return axios.get(`/v2/Dashboard/GetSalesByMonthsAndDays`, { params: query })
    }

    getSalesByYears(query) {
        return axios.get(`/v2/Dashboard/GetSalesByYears`, { params: query })
    }

    getSalesAllSellers(query) {
        return axios.get(`/v2/Dashboard/GetSalesAllSellers`, { params: query })
    }

    getSalesByDayVsPreviousDay(query) {
        return axios.get(`/v2/Dashboard/GetSalesByDayVsPreviousDay`, { params: query })
    }

    getCustomerCharges(query) {
        return axios.get(`/v2/Dashboard/GetCustomerCharges`, { params: query })
    }

    //Bitacoras de la base periferica => Bitacoras   
    getAllBinnacles(query) {
        return axios.get(`/v2/Dashboard/GetAllBinnacles`, { params: query })
    }
}