<template>
    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
        <b-card no-body>
            <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
                <h5 class="mb-0 text-capitalize">Metas de Ventas {{ currentMonth }}</h5>
                <b-button v-if="!isEdit && isAdministrator" @click="isEdit = true" variant="outline-primary" size="sm" class="float-right btn-icon"
                v-b-tooltip.hover.top="`Editar Meta`">
                    <feather-icon icon="EditIcon" size="14" />
                </b-button>
            </b-card-header>            
            <table ref="salesTable" :class="`${isAdministrator ? 'table table-striped table-sm text-small b-table-no-border-collapse small' : 'ml-1'}`">
                <thead v-if="isAdministrator">
                    <tr>
                        <th>Almacén</th>
                        <th class="text-center">Meta</th>
                        <th class="text-center">% </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(sale, index) in saleGoals" :key="index">
                        <td style="font-size: 11px;">{{ sale.almacen }}</td>
                        <td style="width: 200px!important;">
                            <div v-if="isEdit">
                                <b-form-input :ref="'cantidadInput' + index"
                                    :id="`almacen_id-${sale.almacen_Id}`" size="sm" type="number"
                                    v-model="sale.importe" placeholder="0"
                                    @keydown="handleKeyDown(index, $event)"
                                    @input.native="validateItemsToLoad($event, sale)"/>
                            </div>
                            <div v-else>
                                <b-tooltip v-if="isAdministrator" :target="`progress-${sale.almacen_Id}`" placement="top">
                                    {{ sale.porcentaje.toFixed(2) }}%<br>
                                    Venta: {{ formatCurrency(sale.venta_Actual / 1) }}<br>
                                    Meta: {{ formatCurrency(sale.importe/1) }}

                                </b-tooltip>
                                <b-progress
                                    :id="`progress-${sale.almacen_Id}`"
                                    :value="sale.porcentaje"
                                    :variant="getVariant(sale.porcentaje)"
                                    :key="sale.almacen_Id"
                                    animated
                                    max="100"
                                    height="15px"
                                    
                                />
                            </div>
                        </td>
                        <!-- v-b-tooltip.hover
                                    :title="`Venta: ${formatCurrency(sale.venta_Actual / 1)} - Meta: ${formatCurrency(sale.importe/1)}`" -->
                        <!-- <td v-else style="width: 200px!important;">
                            <b-progress
                                :value="sale.porcentaje"
                                :variant="getVariant(sale.porcentaje)"
                                :key="sale.almacen_Id"
                                animated
                                max="100"
                                height="15px"
                            />
                            
                        </td> -->
                        <td>{{ sale.porcentaje }}%</td>
                    </tr>
                </tbody>
            </table>

            <b-card-footer v-if="isEdit && isAdministrator">
                <div class="demo-inline-spacing_ float-right">
                    <b-button @click="isEdit = false" variant="secondary" size="sm" class="mr-2">Cancelar</b-button>
                    <b-button @click="storeGoals" variant="primary" size="sm">Guardar</b-button>
                </div>
            </b-card-footer>            
        </b-card>
    </b-overlay>
</template>

<script>
import moment from 'moment'
import store from '@/store'
import _ from 'lodash'
import {
    BRow, BCol, BCard, BCardHeader, BCardFooter, BCardTitle, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BOverlay, BSpinner, BTable, 
    BFormCheckbox, BFormGroup, BFormInput,
    BModal, VBModal, BForm, BTooltip, VBTooltip,
    BBadge, BProgress, BProgressBar, 
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from "vue-ripple-directive";
import { onMounted, ref } from '@vue/composition-api'
import * as helper from '@/libs/helpers';
import WareHousesProvider from '@/providers/WareHouses'
const WareHousesResource = new WareHousesProvider()

import SaleGoalsProvider from '@/providers/SaleGoals'
import FeatherIcon from './feather-icon/FeatherIcon.vue'
const SaleGoalsResource = new SaleGoalsProvider()

export default {
    name: 'SaleGoals',
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardTitle,
        BCardBody,
        BCardFooter,
        BCardText,
        BButton,
        BAlert,
        BLink,
        BOverlay,
        BSpinner,
        BTable,
        BTableLite,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BModal,
        VBModal,
        BTooltip, 
        VBTooltip,
        BForm,
        BBadge,
        BProgress,
        BProgressBar,

        //Validations
        ValidationProvider,
        ValidationObserver,
        FeatherIcon,
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },
    props: {
        refresh: {
            type: Boolean,
            default: () => false,
            required: true,
        },
    },
    data: () => ({
        timer: null,
    }),
    computed: {
        currentMonth() {
            return moment().format('MMMM, YYYY')
        },  
        isAdministrator () {
            return store.getters['auth/getUser'].customRol.id == store.getters['auth/getAdministratorRol'] ||
            store.getters['auth/getUser'].customRol.id == store.getters['auth/getRolDirector']
        },
    },
    mounted() {
        //Posicionar el focus en el primer input
        this.$nextTick(() => {
            const nextInput = this.$refs['cantidadInput0'];
            if (nextInput) {
                nextInput.focus(); // Enfoca el siguiente input
                nextInput.select(); // Selecciona el texto del input
            }
        });
    },
    methods: {
        getVariant(porcentaje) {
            if (porcentaje >= 75) return 'success'; // Porcentaje alto
            if (porcentaje >= 50) return 'info'; // Porcentaje medio-alto
            if (porcentaje >= 25) return 'warning'; // Porcentaje medio
            return 'danger'; // Porcentaje bajo
        },
        validateItemsToLoad(event, sale) {

            //Validar que el valor ingresado sea un número
            if (isNaN(event.target.value)) {
                sale.importe = 0
                return
            }

            //Validar que el valor ingresado sea mayor a 0
            if (event.target.value < 0) {
                sale.importe = 0
                return
            }

            const venta = sale.venta_Actual * 1
            const meta = event.target.value * 1

            console.log('venta', venta, 'meta', meta)

            //Calcular el porcentaje de la meta 
            sale.porcentaje = (venta > 0 && meta > 0) ? ((venta / meta) * 100).toFixed(2) : 0
            
        },
        handleKeyDown (index, event) {
            if (event.key === 'ArrowDown' || event.key === 'ArrowUp' || event.key === 'Enter') {
                event.preventDefault(); // Evita el comportamiento por defecto del navegador

                let nextIndex = index;
                if (event.key === 'ArrowDown' || event.key === 'Enter') {
                    nextIndex++;
                } else if (event.key === 'ArrowUp') {
                    nextIndex--;
                }

                // Asegúrate de que el índice esté dentro del rango válido
                if (nextIndex >= 0 && nextIndex < this.saleGoals.length) {
                    // Obtén la referencia al siguiente input de la fila                    
                    this.$nextTick(() => {
                        const nextInput = this.$refs['cantidadInput' + nextIndex];
                        if (nextInput) {
                            nextInput[0].focus(); // Enfoca el siguiente input
                            nextInput[0].select(); // Selecciona el texto del input
                        }
                    });
                }
            }
        }
    },
    watch:{
        isEdit(value) {
            if (value) {
                this.$nextTick(() => {
                    // Busca el primer input de referencia
                    const nextInput = this.$refs['cantidadInput0'];

                    // Los elementos generados por v-for suelen ser arreglos en $refs
                    if (nextInput && nextInput.length > 0) {
                        nextInput[0].focus(); // Enfoca el primer input
                        nextInput[0].select(); // Selecciona el texto dentro del input
                    }
                });
            }
        },
        refresh(value) {
            if(value) {
                this.getSaleGoals()
            }
        },
    },
    setup(props, { emit }) {

        const loading = ref(false)
        const saleGoals = ref([])
        const isEdit = ref(false)        

        const getSaleGoals = async () => {
            loading.value = true
            try {
                const { data } = await SaleGoalsResource.index({})
                if (data.isSuccesful) {
                    saleGoals.value = data.data
                }
            }catch(e){
                console.log(e)
            }finally{
                loading.value = false
            }
        }

        const storeGoals = async () => {
            loading.value = true
            const payload = {
                metasVentasDTO: saleGoals.value
            }
            try {

                const { data } = await SaleGoalsResource.store(saleGoals.value)
                if (data.isSuccesful) {
                    helper.success('Metas de ventas guardadas correctamente')
                    isEdit.value = false  
                    await getSaleGoals()

                } else {
                    helper.danger('Ocurrió un error al guardar las metas de ventas')
                }
            }catch(e){
                console.log(e)
                helper.handleResponseErrors(e)
            }finally{
                loading.value = false
            }
        }

       
        onMounted(async () => {
            await getSaleGoals()
        })
        

        return {
            loading,
            isEdit,
            saleGoals,

            //Methods
            getSaleGoals,
            storeGoals,
        }        

    },
}
</script>