<template>
    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
        <b-card no-body title="Polizas Contables" sub-title="Posibles polizas duplicadas">
            <b-card-header>
                <b-card-title>Polizas Contables</b-card-title>
                <b-card-sub-title>
                    Posibles polizas duplicadas
                </b-card-sub-title>
            </b-card-header>
            
            <div class="ml-1 mr-1">
                <b-row>
                    <b-col cols="12" md="6" lg="3">
                        <b-form-group label="Fecha Inicio" label-for="date_start">
                            <flat-pickr v-model="filter.fechaIni" id="date_start" class="form-control flatpickr-small"
                                :config="{ dateFormat: 'Y-m-d' }" placeholder="DD/MM/YYYY" />
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" md="6" lg="3">
                        <b-form-group label="Fecha Fin" label-for="date_start">
                            <flat-pickr v-model="filter.fechaFin" id="date_end" class="form-control flatpickr-small"
                                :config="{ dateFormat: 'Y-m-d' }" placeholder="DD/MM/YYYY" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" lg="2" class="mt-2">
                        <!-- <b-button :disabled="loading" @click="getPolicies" size="sm" variant="primary"> -->
                        <b-button block :disabled="loading" @click="getPolicies" size="sm" variant="primary" class="mb-75">
                            <feather-icon icon="SearchIcon" /> Buscar
                        </b-button>
                    </b-col>
                </b-row>
            </div>

            <div class="table-responsive" :class="`b-table-sticky-header`">
                <table class="table b-table table-sm b-table-no-border-collapse  b-table-selectable b-table-select-single w-100 text-small small">
                    <thead>
                        <tr>
                            <th style="font-size: 9px;">Tipo Poliza</th>
                            <th style="font-size: 9px;">Poliza</th>
                            <th style="font-size: 9px;">Fecha</th>
                            <th class="text-center" style="font-size: 9px!important;">Descripción</th>
                            <th class="text-center" style="font-size: 9px!important;">Importe</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in policies" :key="index" @click="selectRow(item, index)">
                            <td>{{ item.tipo_Poliza }}</td>
                            <td>{{ item.poliza }}</td>
                            <td class="text-capitalize">{{ formatDate(item.fecha) }}</td>
                            <td>{{ item.descripcion}}</td>
                            <td class="text-right">{{ formatCurrency(item.importe) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div> 
            
            <!-- Pagination -->
            <div v-if="totalRecords > 0" class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">{{ resolvePaginationTranslate(dataMeta) }}</span>
                    </b-col>
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <div class="mt-2 demo-spacing-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="pageSize"/>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </b-overlay>
</template>

<script>
import {
	BCard, BCardHeader, BCardTitle, BCardSubTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
	BFormGroup, BFormInput, BFormSelect, BFormCheckbox, BFormRadio, BFormRadioGroup, BFormTextarea, BFormInvalidFeedback,
	BButton, BOverlay, BTable, BTooltip, VBTooltip, BPagination,
} from 'bootstrap-vue'

import flatPickr from "vue-flatpickr-component";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import * as helper from '@/libs/helpers';
import vSelect from "vue-select";
import { heightTransition } from '@core/mixins/ui/transition'
import moment from 'moment';
import store from '@/store'
import { computed, nextTick, onMounted, ref } from "@vue/composition-api";
import Ripple from 'vue-ripple-directive'

import DashboardsProvider from '@/providers/Dashboards'
const DashboardsResource = new DashboardsProvider()

export default {
    name: "AccountingPolicies",
    components: {
        BCard, BCardHeader, BCardTitle, BCardSubTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
        BFormGroup, BFormInput, BFormSelect, BFormCheckbox, BFormRadio, BFormRadioGroup, BFormTextarea, BFormInvalidFeedback,
        BButton, BOverlay, BTable, BTooltip, VBTooltip,
        BPagination,
        flatPickr, vSelect
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
    },
	mixins: [heightTransition],
    computed: {
        dataMeta() {
            const localItemsCount = _.size(this.policies);
            return {
                from:
                    this.pageSize * (this.currentPage - 1) +
                    (localItemsCount ? 1 : 0),
                to: this.pageSize * (this.currentPage - 1) + localItemsCount,
                of: this.totalRecords,
            };
        },
    },
    watch:{
        currentPage(value) {
            this.getPolicies();
        },
        pageSize(value) {
           // this.getPolicies();
        },
    },
    setup() {

        const loading = ref(false);
        const currentPage = ref(1)
        const pageSize = ref(10)
        const totalRecords = ref(0)
        const policies = ref([])

        const filter = ref({
			fechaIni: moment().startOf('month').format('YYYY-MM-DD'),
			fechaFin: moment().format('YYYY-MM-DD'),
            pageNumber: currentPage.value,
            pageSize: pageSize.value,

		})       

        const getPolicies = async () => {

           filter.value.pageNumber = currentPage.value
            loading.value = true
            try {
                const { data } = await DashboardsResource.getPolicies(filter.value)
                if (data.isSuccesful) {
                    policies.value = data.data.data
                    totalRecords.value = data.data.totalRecords
                } else {
                    helper.danger(data.message)
                }
            } catch (error) {
                helper.handleResponseErrors(error)
            } finally {
                loading.value = false
            }
        }

        return {
            loading,
            filter,
            policies,
            currentPage,
            pageSize,
            totalRecords,

            //Methods
            getPolicies
        }        
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
@import "~@core/scss/vue/libs/vue-sweetalert.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
@import '~@core/scss/vue/libs/vue-flatpicker.scss';

</style>

<style>
.b-table-sticky-header {
    overflow-y: auto;
    max-height: 250px!important;
}

.b-table-sticky-header, .table-responsive, [class*=table-responsive-] {
    margin-bottom: 0rem!important;
}

/* Colores para descuentos */
.bg-green-light {
  background-color: #d4f9d4;
}
.bg-yellow-light {
  background-color: #fff9d4;
}
.bg-orange-light {
  background-color: #ffe5cc;
}
.bg-red-light {
  background-color: #ffb3b3; /* Rojo claro */
}
.bg-red-medium {
  background-color: #ff6666; /* Rojo medio */
}
.bg-red-dark {
  background-color: #cc0000; /* Rojo oscuro */
}
.bg-red-intense {
  background-color: #990000; /* Rojo intenso */
}

/* Estilo adicional para tooltips */
.tooltip-inner {
  background-color: #333;
  color: white;
}
</style>