<template>
    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
        <b-card no-body>
            <b-card-header>
                <b-card-title>
                    Flujo
                </b-card-title>
            </b-card-header>

            <div class="ml-1 mr-1">
                <b-row>
                    <b-col cols="12" md="6" lg="3">
                        <b-form-group label="Fecha Inicio" label-for="date_start">
                            <flat-pickr
                                v-model="filter.fechaIni"
                                id="date_start"
                                class="form-control flatpickr-small"
                                :config="{ dateFormat: 'Y-m-d' }"
                                placeholder="DD/MM/YYYY"
                            />
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" md="6" lg="3">
                        <b-form-group label="Fecha Fin" label-for="date_start">
                            <flat-pickr
                                v-model="filter.fechaFin"
                                id="date_end"
                                class="form-control flatpickr-small"
                                :config="{ dateFormat: 'Y-m-d' }"
                                placeholder="DD/MM/YYYY"
                            />
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" md="6" lg="4">
                        <b-form-group label="Almacén" label-for="warehouse_id">
                            <v-select
                                id="warehouse_id"
                                v-model="filter.almacenId"
                                :options="warehouses"
                                :reduce="option => option.almacen_Id"
                                label="nombre"
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                placeholder="Seleccione un almacén"
                                :clearable="false"
                                :searchable="false"
                                class="select-size-sm"/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" lg="2">
                        <b-button block @click="getSales" :disabled="loading" size="sm" variant="primary" class="mr-1 mt-2">
                            <feather-icon icon="SearchIcon" /> Buscar
                        </b-button>
                    </b-col>
                </b-row>
            </div>

            <div class="table-responsive">
                <table role="table" 
					class="table b-table table-sm b-table-no-border-collapse b-table-selectable b-table-select-single w-100 text-small small table-no-inner-borders table-no-outer-border">
                    <thead>
                        <tr>
                            <th scope="col" aria-colindex="1">
                                <div>Concepto</div>
                            </th>
                            <th scope="col" aria-colindex="2">
                                <div>Importe</div>
                            </th>
                        </tr>
                    </thead>
					<tbody>                       
                        <template v-if="sales.length <= 0">
                            <tr>
                                <td role="cell"><strong>Ventas de Contado</strong></td>
								<td role="cell"><strong>{{ formatCurrency(0) }}</strong></td>
                            </tr>
                            <tr class="text-secondary">
                                <td role="cell"><strong>Ventas de Crédito</strong></td>
								<td role="cell"><strong>{{ formatCurrency(0) }}</strong></td>
                            </tr>
                        </template>
                        <template v-else v-for="(item, index) in sales">
							<tr :class="item.metodoPago === 'Contado' ? '' : 'text-secondary'" role="row" @click="toggleDetails(index, 'sales')" :key="index">
								<td role="cell"><strong>Ventas de {{ item.metodoPago }}</strong></td>
								<td role="cell"><strong>{{ formatCurrency(item.subTotal) }}</strong></td>
							</tr>
							<tr v-if="item.showDetails" :key="index + '-details'">
								<td colspan="3">
									<div class="details-container">
										<h4>Detalles de {{ item.metodoPago }}</h4>
										<b-list-group>
											<b-list-group-item v-for="(detalle, detalleIndex) in item.detalles" :key="detalleIndex" class="d-flex justify-content-between align-items-center">
												<span><strong>{{ detalle.formaCobro }}</strong></span>
												<b-badge variant="primary" pill class="badge-round">
													{{ formatCurrency(detalle.subTotal) }}
												</b-badge>
											</b-list-group-item>
										</b-list-group>
										<b-button @click="toggleDetails(index, 'sales')" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary">
											Cerrar detalles
										</b-button>
									</div>
								</td>
							</tr>
						</template>
                       
                        
                        <template v-if="deposits.length <= 0">
                            <tr role="row">
								<td role="cell"><strong>Abonos</strong></td>
								<td role="cell"><strong>{{ formatCurrency(0) }}</strong></td>
							</tr>
                        </template>
                        <template v-else v-for="(item, index) in deposits">
                            <tr role="row" @click="toggleDetails(index, 'deposits')" :key="index+item.metodoPago">
								<td role="cell"><strong>Abonos</strong></td>
								<td role="cell"><strong>{{ formatCurrency(item.total) }}</strong></td>
							</tr>
                            <tr v-if="item.showDetails" :key="index+item.metodoPago + '-details'">
								<td colspan="3">
									<div class="details-container">
										<h4>Detalles de {{ item.metodoPago }}</h4>
										<b-list-group>
											<b-list-group-item v-for="(detalle, detalleIndex) in item.detalles" :key="detalleIndex" class="d-flex justify-content-between align-items-center">
												<span><strong>{{ detalle.formaCobro }}</strong></span>
												<b-badge variant="primary" pill class="badge-round">
													{{ formatCurrency(detalle.total) }}
												</b-badge>
											</b-list-group-item>
										</b-list-group>
										<b-button @click="toggleDetails(index, 'deposits')" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary">
											Cerrar detalles
										</b-button>
									</div>
								</td>
							</tr>
                        </template>

                        <!-- Otros ingresos -->
                        <tr role="row">
                            <td role="cell"><strong>Otros Ingresos</strong></td>
                            <td role="cell"><strong>{{ formatCurrency(0) }}</strong></td>
                        </tr>

                        <template>
                            <tr role="row" @click="payments.showDetails = !payments.showDetails">
                                <td role="cell"><strong>Pagos</strong></td>
                                <td role="cell"><strong>{{ formatCurrency(payments.total) }}</strong></td>
                            </tr>
                            <tr v-if="payments.showDetails" role="cell">
                                <td colspan="3">
                                    <div class="details-container">
                                        <h4>Detalles de Pagos por Tipo Proveedor</h4>
                                        <b-list-group>
                                            <b-list-group-item v-for="(detalle, detalleIndex) in payments.detalles" :key="detalleIndex" class="d-flex justify-content-between align-items-center">
                                                <span><strong>{{ detalle.tipoProveedor }}</strong></span>
                                                <b-badge :variant="'primary'" pill class="badge-round">
                                                    {{ formatCurrency(detalle.total) }}
                                                </b-badge>
                                            </b-list-group-item>
                                        </b-list-group>

                                        <b-button @click="payments.showDetails = false" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary">
                                            Cerrar detalles
                                        </b-button>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <tr>
                            <td colspan="2"><br></td>
                        </tr>
					</tbody>
                    <tfoot>
                        <tr>
                            <th class="font-weight-bold text-right">
                                <h5>TOTAL</h5>
                            </th>
                            <th class="font-weight-bold">
                                <h6>{{ formatCurrency(grandTotal) }}</h6>
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </b-card>
    </b-overlay>
</template>

<script>
import {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BFormRadio,
    BFormRadioGroup,
    BFormTextarea,
    BFormInvalidFeedback,
    BButton,
    BOverlay,
    BTable,
    BTooltip,
    VBTooltip,
    BListGroup,
    BListGroupItem,
    BBadge,
    BLink,
} from "bootstrap-vue";

import flatPickr from "vue-flatpickr-component";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import * as helper from "@/libs/helpers";
import vSelect from "vue-select";
import { heightTransition } from "@core/mixins/ui/transition";
import moment from "moment";
import store from "@/store";
import { computed, nextTick, onMounted, ref } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";

import DashboardsProvider from "@/providers/Dashboards";
const DashboardsResource = new DashboardsProvider();

export default {
    name: "IncomeFlow",
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardTitle,
        BCardText,
        BCardBody,
        BMedia,
        BAvatar,
        BMediaAside,
        BMediaBody,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BFormCheckbox,
        BFormRadio,
        BFormRadioGroup,
        BFormTextarea,
        BFormInvalidFeedback,
        BButton,
        BOverlay,
        BTable,
        BTooltip,
        VBTooltip,
        BListGroup,
        BListGroupItem,
        BBadge,
        BLink,

        //
        flatPickr,
        vSelect,
    },
    directives: {
        Ripple,
        "b-tooltip": VBTooltip,
    },
    mixins: [heightTransition],
    setup() {
        const loading = ref(false);
        const filter = ref({
			fechaIni: moment().startOf("month").format("YYYY-MM-DD"),
			fechaFin: moment().format("YYYY-MM-DD"),
			almacenId: 0,
		});		
		const sales = ref([]);
        const deposits = ref([]);
        const payments = ref([]);

		const warehouses = computed (() => {
			return [{almacen_Id: 0, nombre: 'TODOS'}, ...store.getters["auth/getWareHouses"]];
		});

        const grandTotal = computed(() => {
            let total = 0;
            sales.value.filter(x => x.metodoPago === 'Contado').forEach(item => {
                total += item.subTotal;
            });

            deposits.value.forEach(item => {
                total += item.total;
            });

            total = total - payments.value.total;

            return total;
        }); 

		const toggleDetails = (index, type = 'sales') => {
			if (type === 'sales') {
				sales.value.forEach((item, i) => {
					if (i !== index) {
						item.showDetails = false;
					}
				});
			
				// Alternar la visibilidad de los detalles de la fila seleccionada
				sales.value[index].showDetails = !sales.value[index].showDetails;
			} else {
				deposits.value.forEach((item, i) => {
					if (i !== index) {
						item.showDetails = false;
					}
				});
			
				// Alternar la visibilidad de los detalles de la fila seleccionada
				deposits.value[index].showDetails = !deposits.value[index].showDetails;
			}
		}

		const getSales = async () => {
			loading.value = true;
			try {
				const { data } = await DashboardsResource.getAllTicketsByPaymentMethod(filter.value);
				if (data.isSuccesful) {
					sales.value = data.data.ventas.map(item => {
						item.showDetails = false;
						return item;
					});
					
                    deposits.value = data.data.abonos.map(item => {
                        item.showDetails = false;
                        return item;
                    });

                    payments.value = {
                        ...data.data.pagos,
                        showDetails: false,
                    }
                    

                    console.log(payments.value);

				} else {
					helper.danger(data.message);
				}
			} catch (error) {
				helper.handleResponseErrors(error);
			} finally {
				loading.value = false;
			}
		};

        return {
            loading,
            filter,
			sales,
            payments,
            deposits,

			//Computed
			warehouses,
            grandTotal,

			//Methods
			toggleDetails,
			getSales,
        };
    },
};
</script>

<style>
.details-container {
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    margin-top: 10px;
}

.details-container h4 {
    margin-bottom: 10px;
}

.details-container ul {
    list-style-type: none;
    padding-left: 0;
}

.details-container li {
    margin-bottom: 5px;
}

.list-group-item {
    padding: 0.2rem !important;
    background-color: #f9f9f9 !important;
    border: 0px solid rgba(34, 41, 47, 0.125);
}

/* .table-no-inner-borders td, */
.table-no-inner-borders td {
    border: none !important;
}
.table-no-outer-border {
    /* border: none !important;; */
}
</style>
