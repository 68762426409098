import axios from '@/libs/axios'

export default class SaleGoals {

    index (query) {
        return axios.get(`/v2/MetasVentas/GetAll`, { params: query})
    }

    store (data) {
        return axios.post(`/v2/MetasVentas/Store`, data)
    }
}